import $ from 'jquery';
import jswl from 'js-wrapper-lib';

class ApiRequestHelper {
    constructor(baseApiUrl) {
        this.apiToken = '';
        this.baseUrl = baseApiUrl;
    }

    /**
     *
     * @param {srting} $token
     */
    setToken($token) {
        this.apiToken = $token;
    }

    /**
     * Обобщенный метод для отправки запроса. Его должны использовать уже конкретные методы, напр. post()
     *
     * @param {string} type    тип запроса (GET, POST и т.д.)
     * @param {string} url     url отностельно базового (this.baseUrl), например вместо '/api/v1/user/login' передавайте просто 'user/login'
     * @param {mixed} data     данные которые хотим передать
     * @param {string} requestDataType Опционально: формат тела запроса по умолчаню json.
     * @param {string} responseDataType Опционально: ожидаемый формат тела ответа по умолчаню json.
     * @param {object} headers Опционально: нестандартные заголовки
     */
    send(type, url, data = null, requestDataType = 'json', responseDataType = 'json', headers = {}) {
        if (!jswl.isEmpty(this.apiToken)) {
            // если токен установлен, добавив его в заголовки
            headers = { ...headers, ...{ 'X-AUTH-TOKEN': this.apiToken } };
        }
        if (type !== 'GET') {
            // если это не GET

            if (requestDataType === 'json') {
                // если ождиается отправка json
                (data = JSON.stringify(data)), (headers = { ...headers, ...{ 'Content-Type': 'application/json' } });
            } else if (requestDataType === 'formdata') {
                // если ождиается отправка formdata
                let formdata = new FormData();
                for (let key in data) {
                    formdata.append(key, data[key]);
                }
                data = formdata;
            }
        }

        var Request = {
            type: type, // метод
            url: this.baseUrl + url, // адрес отправки (url)
            dataType: responseDataType, // тип возвращаемого значения
            headers,
            ...(requestDataType === 'formdata' && {
                contentType: false,
                processData: false,
            }),
            xhrFields: {
                withCredentials: true,
            },
        };

        Request = { ...Request, data: data };

        // console.log(type, Request, 'Request');

        return $.ajax(Request);
    }

    /**
     * Отправит запрос методом POST
     *
     * @param {string} url     url отностельно базового (this.baseUrl), например вместо '/api/v1/user/login' передавайте просто 'user/login'
     * @param {mixed} data     данные которые хотим передать
     * @param {string} requestDataType Опционально: формат тела запроса, по умолчаню json.
     * @param {string} responseDataType Опционально: ожидаемый формат тела ответа по умолчаню json
     * @param {object} headers Опционально: нестандартные заголовки
     */
    post(url, data = null, requestDataType = 'json', responseDataType = 'json', headers = {}) {
        return this.send('POST', url, data, requestDataType, responseDataType, headers);
    }

    /**
     * Отправит запрос методом GET
     *
     * @param {string} url     url отностельно базового (this.baseUrl), например вместо '/api/v1/user/login' передавайте просто 'user/login'
     * @param {mixed} data     данные которые хотим передать
     * @param {string} requestDataType Опционально: формат тела запроса, по умолчаню json.
     * @param {string} responseDataType Опционально: ожидаемый формат тела ответа по умолчаню json
     * @param {object} headers Опционально: нестандартные заголовки
     */
    get(url, data = null, requestDataType = 'json', responseDataType = 'json', headers = {}) {
        return this.send('GET', url, data, requestDataType, responseDataType, headers);
    }

    /**
     * Отправит запрос методом PUT
     *
     * @param {string} url     url отностельно базового (this.baseUrl), например вместо '/api/v1/user/login' передавайте просто 'user/login'
     * @param {mixed} data     данные которые хотим передать
     * @param {string} requestDataType Опционально: формат тела запроса, по умолчаню json.
     * @param {string} responseDataType Опционально: ожидаемый формат тела ответа по умолчаню json
     * @param {object} headers Опционально: нестандартные заголовки
     */
    put(url, data = null, requestDataType = 'json', responseDataType = 'json', headers = {}) {
        return this.send('PUT', url, data, requestDataType, responseDataType, headers);
    }

    /**
     * Отправит запрос методом DELETE
     *
     * @param {string} url     url отностельно базового (this.baseUrl), например вместо '/api/v1/user/login' передавайте просто 'user/login'
     * @param {mixed} data     данные которые хотим передать
     * @param {string} requestDataType Опционально: формат тела запроса, по умолчаню json.
     * @param {string} responseDataType Опционально: ожидаемый формат тела ответа по умолчаню json
     * @param {object} headers Опционально: нестандартные заголовки
     */
    delete(url, data = null, requestDataType = 'json', responseDataType = 'json', headers = {}) {
        return this.send('DELETE', url, data, requestDataType, responseDataType, headers);
    }
}

export default ApiRequestHelper;
