import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getWorkTypes, getPkkData } from '@app-actions';
import RoutePath from '@app-universal/routing/RoutePath';
import IconEdit from '@app-images/icon_edit.svg';
import CommonInfoBlock from './includes/CommonInfoBlock';
import WorksBlock from './includes/WorksBlock';
import DataFromPKKBlock from './includes/DataFromPKKBlock';
import SimpleTabs from '@app-universal/navigation/SimpleTabs';

const tabsLabels = ['Общая информация', 'Регламентные работы', 'Данные с ПКК'];

export default function ObjectView({
    toggleMode,
    showEditIcon,
    isFromIframe,
    ID_6993,
    address,
    area,
    objectClass,
    district,
    group,
    id,
    len_6993,
    len_curb,
    len_nocurb,
    type_serv,
    type_surve,
    type_zone,
    showLinkToMap,
    geometryAsText,
    geometryAsGeojson,
}) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        isWorkTypesLoaded: false,
        isPkkDataLoaded: false,
        workTypes: [],
        rosreestr: [],
    });

    const loadWorkTypes = (type_surve_id, type_zone_id) => {
        dispatch(
            getWorkTypes({ type_surve_id, type_zone_id }, (resp) => {
                setState((pr) => ({ ...pr, isWorkTypesLoaded: true, workTypes: resp.items }));
            })
        );
    };

    useEffect(() => {
        if (type_surve?.id && type_zone?.id) {
            loadWorkTypes(type_surve.id, type_zone.id);
        }
    }, [type_surve, type_zone]);

    const handleSuccessPkkDataLoad = (response) => {
        setState((pr) => ({ ...pr, rosreestr: response, isPkkDataLoaded: true }));
    };

    useEffect(() => {
        getPkkData(geometryAsGeojson, handleSuccessPkkDataLoad);
    }, [geometryAsGeojson]);

    console.log('type_surve', type_surve);
    console.log('type_zone', type_zone);

    const tabsContent = [
        <CommonInfoBlock
            group={group}
            objectClass={objectClass}
            district={district}
            ID_6993={ID_6993}
            len_6993={len_6993}
            address={address}
            type_zone={type_zone}
            type_surve={type_surve}
            type_serv={type_serv}
            area={area}
            len_curb={len_curb}
            len_nocurb={len_nocurb}
            tasks={state.workTypes}
        />,
        <WorksBlock tasks={state.workTypes} isWorkTypesLoaded={state.isWorkTypesLoaded} />,
        <DataFromPKKBlock rosreestr={state.rosreestr} isPkkDataLoaded={state.isPkkDataLoaded} />,
    ];

    return (
        <div className="info-modals-holder">
            <div className="b-modal-info" data-modal>
                <div className="modal-content-wrapper">
                    <div className="modal-header ">
                        <div className="header-left">
                            <div className="title">{(address && address.trim()) || 'Адрес не указан'}</div>
                            {showLinkToMap && (
                                <Link to={{ pathname: RoutePath('map'), state: { geometry: geometryAsText } }}>
                                    <span className="map-link">Перейти к карте</span>
                                </Link>
                            )}
                        </div>
                        {showEditIcon && (
                            <div className="header-right">
                                <div className="edit-link" onClick={toggleMode}>
                                    <img className="icon" src={IconEdit} alt="IconEdit" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="modal-body">
                        <SimpleTabs tabsLabels={tabsLabels} tabsContent={tabsContent} />
                    </div>
                </div>
            </div>
        </div>
    );
}

ObjectView.defaultProps = {
    showEditIcon: false,
    isFromIframe: false,
    showLinkToMap: true,
};

ObjectView.propTypes = {
    toggleMode: PropTypes.func.isRequired,
    showEditIcon: PropTypes.bool,
    isFromIframe: PropTypes.bool,
    showLinkToMap: PropTypes.bool,
};
