let ActionTypes = {
    SET_CURRENT_LOCATION: null,
    APP_INITED: null,
    AUTHED: null,
    LOGOUT: null,
    SHOW_MAIN_PROGRESS: null,
    HIDE_MAIN_PROGRESS: null,
    ERROR: null,
};

for (const key of Object.keys(ActionTypes)) {
    ActionTypes[key] = key;
}

ActionTypes = Object.freeze(ActionTypes);

export { ActionTypes };
