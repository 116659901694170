let testServices = [
    {
        id: 1,
        name: 'Механическая мойка дорог',
        placeToDo: 'Проезжая часть',
        surfaceType: 'Усовершенствование',
    },
    {
        id: 2,
        name: 'Механическая мойка дорог',
        placeToDo: 'Проезжая часть',
        surfaceType: 'Усовершенствование',
    },
    {
        id: 3,
        name: 'Механическая мойка дорог',
        placeToDo: 'Проезжая часть',
        surfaceType: 'Усовершенствование',
    },

];

testServices = Object.freeze(testServices);

export { testServices };
