import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash-es/isObject';
import jswl from 'js-wrapper-lib';
import { createStrFromTasksArr } from '@app-pages/Object/objectHelpers';
import { formatLength, formatSquare } from '@app-helpers/formatters';

function CommonInfoBlock({
    group,
    objectClass,
    district,
    ID_6993,
    len_6993,
    address,
    type_zone,
    type_surve,
    type_serv,
    area,
    len_curb,
    len_nocurb,
    tasks,
}) {
    return (
        <div className="tabs-content-item faded">
            <div className="info-holder with-border">
                <div className="info-title">Информация об объекте</div>
                <div className="info-group">
                    <div className="info-group-title">Группа</div>
                    <div className="info-group-value">{isObject(group) ? group.name : group}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Класс территории</div>
                    <div className="info-group-value">{isObject(objectClass) ? objectClass.name : objectClass}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Внутригородской округ</div>
                    <div className="info-group-value">
                        {jswl.isDefined(district)
                            ? jswl.isEmpty(district.trim())
                                ? 'Округ не указан'
                                : district
                            : 'Округ не указан'}
                    </div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Идентификатор</div>
                    <div className="info-group-value">
                        {jswl.isDefined(ID_6993)
                            ? jswl.isEmpty(ID_6993.trim())
                                ? 'Идентификатор не указан'
                                : ID_6993
                            : 'Идентификатор не указан'}
                    </div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Длина улицы, м</div>
                    <div className="info-group-value">{len_6993 ? formatLength(len_6993) : 'Не указана'}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Адрес</div>
                    <div className="info-group-value">{address || 'Не указано'}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Вид территории</div>
                    <div className="info-group-value">{isObject(type_zone) ? type_zone.name : type_zone}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Вид покрытия</div>
                    <div className="info-group-value">{isObject(type_surve) ? type_surve.name : type_surve}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Наименование видов услуг выполненных работ</div>
                    <div className="info-group-value">{createStrFromTasksArr(tasks)}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Площадь, м2</div>
                    <div className="info-group-value">{area ? formatSquare(area) : 'Не указана'}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Протяженность вдоль бордюра, м</div>
                    <div className="info-group-value">{len_curb ? formatLength(len_curb) : 'Не указана'}</div>
                </div>
                <div className="info-group">
                    <div className="info-group-title">Протяженность без бордюров, м</div>
                    <div className="info-group-value">{len_nocurb ? formatLength(len_nocurb) : 'Не указана'}</div>
                </div>
            </div>
        </div>
    );
}

CommonInfoBlock.defaultProps = {
    tasks: [],
};

CommonInfoBlock.propTypes = {
    tasks: PropTypes.array,
};

export default CommonInfoBlock;
