import React from 'react';
import { getZoneList } from '@app-actions';
import ZoneListTable from './ZoneListTable';
import EntityList from '@app-universal/list/EntityList';
import ZoneFilter from '../ZoneFilter/ZoneFilter';

const routes = [
    {
        label: 'Справочники',
        link: 'handbooks',
        disabled: true,
    },
    {
        label: 'Виды территорий',
        link: 'handbooks/zone/list',
    },
];

function ZoneList(props) {
    const itemsListComponentCallback = React.useCallback((listState, paginationState, setPaginationState, Tools) => {
        return (
            <ZoneListTable
                listState={listState}
                paginationState={paginationState}
                setPaginationState={setPaginationState}
                Tools={Tools}
            />
        );
    }, []);

    const listFilterComponentCallback = React.useCallback((props) => {
        const { filterFSH, setFilterState, clearFilter, listState, toggleSidebar } = props;
        return (
            <ZoneFilter
                formState={filterFSH?.formState}
                clearFilter={clearFilter}
                toggleSidebar={toggleSidebar}
                listState={listState}
                setFilterState={setFilterState}
                emptyFilterState={emptyFilterState}
            />
        );
    }, []);

    const defaultFilterState = React.useMemo(() => {
        return {
            name: '',
        };
    }, []);

    const emptyFilterState = React.useMemo(() => {
        return {
            name: '',
        };
    }, []);

    return (
        <div className="b-reference-book">
            <div className="g-container">
                <EntityList
                    itemsListComponentCallback={itemsListComponentCallback}
                    listFilterComponentCallback={listFilterComponentCallback}
                    pageTitle="Виды территорий"
                    loadItemsListCallback={getZoneList}
                    defaultFilterState={defaultFilterState}
                    routes={routes}
                    emptyFilterState={emptyFilterState}
                    initialItemsPerPage={50}
                />
            </div>
        </div>
    );
}

export default ZoneList;
