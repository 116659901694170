import { UserRoles } from '@app/constants/userRoles';
let routes = [
    {
        label: 'УДС',
        link: 'passport/list',
        children: null,
        roles: [ UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr, UserRoles.controller.valueStr],
        isPrivate: true
    },
    {
        label: 'Скверы',
        link: 'recreation/list',
        children: null,
        roles: [ UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr, UserRoles.controller.valueStr],
        isPrivate: true
    },
    {
        label: 'Справочники',
        link: 'handbooks',
        isMainCategoryClickable: false,
        children: [
            {
                label: 'Список услуг',
                link: 'handbooks/service/list',
            },
            {
                label: 'Виды поверхностей',
                link: 'handbooks/surface/list',
            },
            {
                label: 'Виды территорий',
                link: 'handbooks/zone/list',
            },
        ],
        roles: [ UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr],
        isPrivate: true
    },
    {
        label: 'Управление доступом',
        link: 'users',
        roles: [UserRoles.superAdmin.valueStr],
        isPrivate: true
    },
    {
        label: 'Территории',
        link: 'object/list',
        children: null,
        roles: [ UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr, UserRoles.controller.valueStr],
        isPrivate: true
    },
    {
        label: 'Карта',
        link: 'map',
        children: null,
        roles: [ UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr, UserRoles.controller.valueStr],
        isPrivate: false
    },
];

routes = Object.freeze(routes);

export { routes };
