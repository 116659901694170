import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateUser } from '@app-actions';
import FromStateHelper from '@app-universal/form/FromStateHelper';
import { getFullName } from '../userHelpers';
import UserEditForm from './UserEditForm';

const UserEdit = ({ userData, toggleSidebar, reloadList }) => {
    const dispatch = useDispatch();
    const { id, email, firstName, surname, roles, secondName, phone, isActive } = userData;

    const [state, setState] = useState({ backendValidationErrors: {}, lockSubmit: false });
    const userStateHelper = FromStateHelper(
        {
            email,
            firstName,
            isActive,
            // plainPassword: '',
            // positionHeld: '',
            roles: roles,
            phone,
            secondName,
            surname,
        },
        {}
    );
    const { formState, setFormState, getFormState } = userStateHelper;

    const handleReset = () => toggleSidebar();
    const handleSubmit = (event) => {
        event.preventDefault();
        setState((pr) => ({ ...pr, lockSubmit: true }));

        dispatch(
            updateUser(
                id,
                formState,
                () => {
                    toggleSidebar();
                    reloadList();
                },
                () => setState((pr) => ({ ...pr, lockSubmit: false }))
            )
        );
    };
    const handleFail = () => {};

    return (
        <UserEditForm
            firstName={formState.firstName}
            surname={formState.surname}
            secondName={formState.secondName}
            phone={formState.phone}
            email={formState.email}
            roles={formState.roles}
            isActive={formState.isActive}
            onCancel={handleReset}
            onSubmit={handleSubmit}
            onError={handleFail}
            fullName={getFullName(firstName, surname)}
            handleChangeSelect={userStateHelper.handleChangeInt}
            handleChangeText={userStateHelper.handleChangeText}
            handleChangeCheckbox={userStateHelper.handleChangeCheckbox}
            disableForm={state.lockSubmit}
            backendValidationErrors={state.backendValidationErrors}
        />
    );
};

UserEdit.propTypes = {
    userData: PropTypes.object.isRequired,
    toggleSidebar: PropTypes.func,
    reloadList: PropTypes.func,
};

export default UserEdit;
