import React from 'react';
import { useSelector } from 'react-redux';
import jswl from 'js-wrapper-lib';
import { DataGrid } from '@material-ui/data-grid';
import queryString from 'query-string';
import { getCurrentUser } from '@app-redusers';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import ObjectCreate from '@app-pages/Object/ObjectCreate/ObjectCreate';
import ObjectFilter from '@app-pages/Object/ObjectList/ObjectFilter/ObjectFilter';
import ObjectIndex from '@app-pages/Object/ObjectIndex';
import { getObjectList } from '@app-actions';
import EntityList from '@app-universal/list/EntityList';
import ObjectListTable from './ObjectListTable';
import { baseApiUrl } from '@app-helpers/clientConfig';
import { UserRoles } from '@app/constants/userRoles';

const routes = [
    {
        label: 'Территории',
        link: 'object/list',
    },
];

function ObjectList(props) {
    const currentUser = useSelector(getCurrentUser);
    const itemsListComponentCallback = React.useCallback((listState, paginationState, setPaginationState, Tools) => {
        return (
            <ObjectListTable
                listState={listState}
                paginationState={paginationState}
                setPaginationState={setPaginationState}
                Tools={Tools}
            />
        );
    }, []);

    const listFilterComponentCallback = React.useCallback((props) => {
        const { filterFSH, clearFilter, listState, toggleSidebar, setFilterState } = props;
        return (
            <>
                <ObjectFilter
                    formState={filterFSH?.formState}
                    clearFilter={clearFilter}
                    toggleSidebar={toggleSidebar}
                    listState={listState}
                    setFilterState={setFilterState}
                    emptyFilterState={emptyFilterState}
                />
            </>
        );
    }, []);

    const itemCreateComponentCallback = React.useCallback((props) => {
        const { toggleSidebar, filterFSH, listState, Tools } = props;

        return <ObjectCreate toggleSidebar={toggleSidebar} reloadList={Tools.reloadListCallback} />;
    }, []);

    const primaryBtnComponentCallback = React.useCallback(({ filterState, listState }) => {
        const disabled = !listState.downloadingComplete || !listState.items.length;
        const hasAccess = jswl.arr.isAnyInArray(currentUser.roles, [
            UserRoles.superAdmin.valueStr,
            UserRoles.specialist.valueStr,
        ]);
        return (
            hasAccess && ( //TODO Подумать, как вынести это в EntityList
                <PrimaryButton
                    disabled={disabled}
                    onClick={() => {
                        window.open(baseApiUrl + 'territory/get_xlsx?' + queryString.stringify(filterState));
                    }}
                    title="Выгрузить в xlsx"
                />
            )
        );
    }, []);

    const defaultFilterState = React.useMemo(() => {
        return {
            query: '',
            district: '',
            group_id: 0,
            class_id: 0,
            ID_6993: '',
            type_zone_id: 0,
            type_surve_id: 0,
        };
    }, []);

    const emptyFilterState = React.useMemo(() => {
        return {
            query: '',
            district: '',
            group_id: 0,
            class_id: 0,
            ID_6993: '',
            type_zone_id: 0,
            type_surve_id: 0,
        };
    }, []);

    return (
        <>
            <section className="b-reference-book territory-list">
                <div class="g-container">
                    <EntityList
                        itemsListComponentCallback={itemsListComponentCallback}
                        listFilterComponentCallback={listFilterComponentCallback}
                        itemCreateComponentCallback={itemCreateComponentCallback}
                        pageTitle="Территории"
                        loadItemsListCallback={getObjectList}
                        defaultFilterState={defaultFilterState}
                        primaryBtnLabel="Выгрузить в xlsx"
                        emptyFilterState={emptyFilterState}
                        primaryBtnComponentCallback={primaryBtnComponentCallback}
                        routes={routes}
                        initialItemsPerPage={50}
                    />
                </div>
            </section>
        </>
    );
}

export default ObjectList;
