let testObjects = [
    {
        id: 1,
        group: 1,
        class: 2,
        district: 'улица Красная от ул. Советская до ул. Офицерская',
        ID_6993: '03401ОПМГ0898',
        length: 5133.1,
        object_view: 'Проезжая часть',
        coating_view: 'Усовершенствованное',
        service_view: 'Подметание',
        length_with: '4922,6',
        length_without: '210,5',
    },
    {
        id: 2,
        group: 1,
        class: 2,
        district: 'улица Красная от ул. Советская до ул. Офицерская',
        ID_6993: '03401ОПМГ0898',
        length: 5133.1,
        object_view: 'Проезжая часть',
        coating_view: 'Усовершенствованное',
        service_view: 'Подметание',
        length_with: '4922,6',
        length_without: '210,5',
    },
    {
        id: 3,
        group: 1,
        class: 2,
        district: 'улица Красная от ул. Советская до ул. Офицерская',
        ID_6993: '03401ОПМГ0898',
        length: 5133.1,
        object_view: 'Проезжая часть',
        coating_view: 'Усовершенствованное',
        service_view: 'Подметание',
        length_with: '4922,6',
        length_without: '210,5',
    },
    {
        id: 4,
        group: 1,
        class: 2,
        district: 'улица Красная от ул. Советская до ул. Офицерская',
        ID_6993: '03401ОПМГ0898',
        length: 5133.1,
        object_view: 'Проезжая часть',
        coating_view: 'Усовершенствованное',
        service_view: 'Подметание',
        length_with: '4922,6',
        length_without: '210,5',
    },
    {
        id: 5,
        group: 1,
        class: 2,
        district: 'улица Красная от ул. Советская до ул. Офицерская',
        ID_6993: '03401ОПМГ0898',
        length: 5133.1,
        object_view: 'Проезжая часть',
        coating_view: 'Усовершенствованное',
        service_view: 'Подметание',
        length_with: '4922,6',
        length_without: '210,5',
    },
];

testObjects = Object.freeze(testObjects);

export { testObjects };
