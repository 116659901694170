import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const UserModalLoader = () => {
    return (
        <div class="b-modal-edit">
            <div class="modal-content-wrapper">
                <div class="modal-header with-small-icon">
                    <div class="header-left">
                        <div class="title">Пожалуйста подождите...</div>
                    </div>
                    <div class="header-right">
                        <CircularProgress />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModalLoader;
