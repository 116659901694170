import sendRequest from '@app-helpers/ApiRequestHelper/ApiClient';

export const getUserList =
    (successCallback, params = null) =>
    async (dispatch, getState) => {
        sendRequest(dispatch, {
            method: 'get',
            url: 'user',
            data: params,
            successCallback: successCallback,
        });
    };

export const getUser = (userId, successCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'get',
        url: 'user/' + userId,
        successCallback: successCallback,
    });
};

export const updateUser = (userId, data, successCallback, failCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'put',
        url: 'user/' + userId,
        data: data,
        successCallback: successCallback,
        failCallback: failCallback,
        successMessage: 'Данные сохранены!',
    });
};

export const createUser = (data, successCallback, failCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'post',
        url: 'user',
        data: data,
        successCallback: successCallback,
        failCallback: failCallback,
        successMessage: 'Данные сохранены!',
    });
};

export const updateCurrentUser = (data, successCallback, failCallback) => async (dispatch) => {
    sendRequest(dispatch, {
        method: 'put',
        url: 'user',
        data: data,
        successCallback: successCallback,
        failCallback: failCallback,
        successMessage: 'Данные сохранены!',
    });
};

export const deleteUser = (itemId, successCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'delete',
        url: 'user/' + itemId,
        successCallback: successCallback,
        successMessage: 'Данные сохранены!',
    });
};
