import React, { useState, useEffect } from 'react';
import { baseMapUrl } from '@app-helpers/clientConfig';
import ObjectIndex from '@app-pages/Object/ObjectIndex';
import ObjectIndexDuplicate from '@app-pages/Object/ObjectIndexDuplicate';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import IconFilter from '@app-images/icon_filter.svg';
import { useLocation } from 'react-router-dom';
import PassportIndex from '@app-pages/Passport/PassportView/PassportIndex';
import { cadastralMapAdminPassword, cadastralMapAdminLogin, getPassportPreviewUrl } from '@app-helpers/clientConfig';

const styles = {
    mapWrapper: {
        width: '100%',
        height: '100%',
    },
};

const pass = cadastralMapAdminPassword;
const login = cadastralMapAdminLogin;

const defaultPreviewWidth = 600;
const defaultPreviewHeight = 200;

const transformDomain = (domain) =>
    domain && domain !== 'cityhall.krd' ? domain.split('.').splice(-2).join('.') : domain;

document.addEventListener('DOMContentLoaded', () => (document.domain = transformDomain(document.domain)));

const onLoad = (geom) => {
    //document.domain = 'monitorukhb.cityhall.krd';
    console.log('success');
    const iframe = document.getElementById('mapIframe');
    const innerDocument = iframe.contentWindow.document;
    console.log('innerDocument', innerDocument);
    const loginInput = innerDocument.querySelector('input[name="login"]');
    console.log('loginInput', loginInput);
    const passwordInput = innerDocument.querySelector('input[name="password"]');
    console.log('passwordInput', passwordInput);

    console.log('1iframe.contentWindow.location.href', iframe.contentWindow.location.href);
    if (loginInput && passwordInput) {
        loginInput.value = login;
        passwordInput.value = pass;

        const form = innerDocument.getElementsByTagName('form')[0];
        form.submit();
    }

    console.log('iframe.contentWindow', iframe.contentWindow);
    if (geom) {
        console.log('2iframe.contentWindow.location.href', iframe.contentWindow.location.href);
        console.log('innerDocument.readyState', innerDocument.readyState);
        if (innerDocument.readyState === 'complete') {
            setTimeout(() => {
                zoomToFeat(iframe.contentWindow, geom);
            }, 2000);
        } else {
            innerDocument.onreadystatechange = () => {
                zoomToFeat(iframe.contentWindow, geom);
            };
        }
    }
};

const onLoadWithDelay = () => setTimeout(onLoad, 2000);

const zoomToFeat = (iframeWindow, geom) => {
    console.log('zoom', geom);
    if (!geom) return;
    console.log('iframeWindow', iframeWindow);
    iframeWindow.WIDGET.map.zoomToFeature(iframeWindow.WIDGET.featureHighlighter._highlightFeature({ geom: geom }));
};

function CadastralMap(props) {
    const { state: locationState } = useLocation();
    const [state, setState] = useState({ data: null, showSidebar: false });

    const closeSidebar = () => setState((pr) => ({ ...pr, showSidebar: false }));

    useEffect(() => {
        const messageHandler = ({ origin, data }) => {
            if (origin.startsWith(baseMapUrl)) {
                console.count('ВЫЗОВ');
                console.log('Данные с приложения карт: ', data);
                const objectData = { ...data, ...data.fields, geom: data.geom };
                // console.log(objectData);
                setState((pr) => ({ ...pr, data: objectData, showSidebar: true }));
            }
        };

        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, []);

    console.log('state.data', state.data);
    // console.log('state.layer_id', state.data?.layer_id);
    console.log('getPassportPreviewUrl', getPassportPreviewUrl(state.data?.id, state.data?.layer_id, 600, 200));


    return (
        <section className="b-map">
            <SidebarActivator
                render={(toggleSidebar) => (
                    <div className="map-wrapper" style={styles.mapWrapper}>
                        <iframe
                            src={`${baseMapUrl}/resource/1/display?panel=layers`}
                            allow="fullscreen"
                            frameBorder="0"
                            height="100%"
                            width="100%"
                            id="mapIframe"
                            onLoad={() => onLoad(locationState?.geometry)}
                        ></iframe>
                    </div>
                )}
                renderContent={(toggleSidebar) =>
                    state.data?.fields.type === 'territory' ? (
                        <ObjectIndexDuplicate
                            id={state.data?.id}
                            geom={state.data?.geom}
                            objectData={state.data}
                            toggleSidebar={toggleSidebar}
                        />
                    ) : (
                        <PassportIndex
                            passportId={state.data?.id}
                            preview={getPassportPreviewUrl(state.data?.id, state.data?.layer_id, defaultPreviewWidth, defaultPreviewHeight)}
                            toggleSidebar={toggleSidebar}
                            secondaryBtnTitle="К журналу"
                            secondaryBtnLink="passport/list"
                        />
                    )
                }
                isOpen={state.showSidebar}
                beforeCloseCallback={closeSidebar}
            />
        </section>
    );
}

export default CadastralMap;