import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectValidationInput } from '@app-universal/form/inputs/SelectValidationInput';
import { TextValidationInput } from '@app-universal/form/inputs/TextValidationInput';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import BackFrontValidationForm from '@app-universal/form/BackendValidationSupport/BackFrontValidationForm';
import { CheckboxInput } from '@app-universal/form/inputs/CheckboxInput';
import InputMask from 'react-input-mask';
import InputLabel from '@material-ui/core/InputLabel';


const UserEditForm = ({
    email,
    firstName,
    surname,
    secondName,
    roles,
    fullName,
    phone,
    isActive,
    onSubmit,
    onCancel,
    onError,
    handleChangeSelect,
    handleChangeText,
    handleChangeCheckbox,
    disableForm,
    backendValidationErrors,
}) => {
    return (
        <div class="edit-modals-holder">
            <div class="b-modal-edit">
                <div class="modal-content-wrapper">
                    <div class="modal-header with-small-icon">
                        <div class="title">{fullName}</div>
                    </div>
                    <div class="modal-body without-tabs">
                        <div class="info-title">Информация</div>
                        <BackFrontValidationForm
                            backendValidationErrors={backendValidationErrors}
                            onSubmit={onSubmit}
                            onError={onError}
                        >
                            <div class="b-form">
                                <div class="form-wrapper">
                                    <div className="form-row one-column">
                                        <div class="form-element">
                                            <InputLabel for="firstName" class="form-label" required={true}>
                                                Имя
                                            </InputLabel>
                                            <TextValidationInput
                                                variant="outlined"
                                                id="firstName"
                                                name="firstName"
                                                placeholder="Введите имя"
                                                value={firstName}
                                                onChange={handleChangeText}
                                                validators={['required']}
                                                errorMessages={['Поле необходимо заполнить']}
                                                withRequiredValidator
                                                disabled={disableForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row one-column">
                                        <div class="form-element">
                                            <InputLabel for="surname" class="form-label" required={true}>
                                                Фамилия
                                            </InputLabel>
                                            <TextValidationInput
                                                variant="outlined"
                                                id="surname"
                                                name="surname"
                                                placeholder="Введите фамилию"
                                                value={surname}
                                                onChange={handleChangeText}
                                                validators={['required']}
                                                errorMessages={['Поле необходимо заполнить']}
                                                withRequiredValidator
                                                disabled={disableForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row one-column">
                                        <div class="form-element">
                                            <InputLabel for="secondName" class="form-label">
                                                Отчество
                                            </InputLabel>
                                            <TextValidationInput
                                                variant="outlined"
                                                id="secondName"
                                                name="secondName"
                                                placeholder="Введите отчество"
                                                value={secondName}
                                                onChange={handleChangeText}
                                                withRequiredValidator
                                                disabled={disableForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row one-column">
                                        <div class="form-element">
                                            <InputLabel for="phone" class="form-label">
                                                Телефон
                                            </InputLabel>
                                            <InputMask
                                                mask='+7 (999) 999 99 99'
                                                value={phone}
                                                onChange={handleChangeText}
                                                disabled={disableForm}
                                            >
                                                {(inputProps) =>
                                                    <TextValidationInput
                                                        {...inputProps}
                                                        variant='outlined'
                                                        id='phone'
                                                        name='phone'
                                                        placeholder='Введите номер телефона'
                                                        onChange={handleChangeText}
                                                        disabled={disableForm}
                                                        withRequiredValidator
                                                    />}
                                            </InputMask>
                                        </div>
                                    </div>
                                    <div className="form-row one-column">
                                        <div class="form-element">
                                            <InputLabel for="email" class="form-label" required={true}>
                                                Email
                                            </InputLabel>
                                            <TextValidationInput
                                                variant="outlined"
                                                id="email"
                                                name="email"
                                                placeholder="Введите Email"
                                                value={email}
                                                onChange={handleChangeText}
                                                withRequiredValidator
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Поле необходимо заполнить', 'Некорректный email']}
                                                disabled={disableForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row one-column">
                                        <div class="form-element">
                                            <CheckboxInput
                                                value={isActive}
                                                onChange={handleChangeCheckbox}
                                                name="isActive"
                                                label="Пользователь активен"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="controls-wrapper">
                                    <div class="form-row controls-holder">
                                        <SecondaryButton
                                            type="button"
                                            title="Отменить"
                                            onClick={onCancel}
                                            variant="outlined"
                                        />
                                        <PrimaryButton disabled={disableForm} type="submit" title="Сохранить" />
                                    </div>
                                </div>
                            </div>
                        </BackFrontValidationForm>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserEditForm.propTypes = {
    email: PropTypes.string,
    firstName: PropTypes.string,
    surname: PropTypes.string,
    secondName: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
    isActive: PropTypes.bool,
    onSubmit: PropTypes.func,
    /*onSubmit: PropTypes.func,*/
    onError: PropTypes.func,
    handleChangeSelect: PropTypes.func,
    handleChangeText: PropTypes.func,
    handleChangeCheckbox: PropTypes.func,
    disableForm: PropTypes.bool,
    backendValidationErrors: PropTypes.object,
};

export default UserEditForm;
