export const baseUrl = process.env.BASE_URL;
export const baseApiUrl = baseUrl + process.env.API_URL_PREFIX;
export const baseImageUrl = baseUrl + 'uploads/';

export const baseMapUrl = process.env.BASE_MAP_URL;
export const cadastralMapAdminLogin = process.env.CADASTRAL_MAP_ADMIN_LOGIN || 'administrator';
export const cadastralMapAdminPassword = process.env.CADASTRAL_MAP_ADMIN_PASS || 'sc5kT2Ns';

export const getPassportPreviewUrl = (passportId, layerId, width, height) => {
    if (!passportId || !layerId || !width || !height) return null;
    return `${baseMapUrl}/api/resource/${layerId}/feature/${passportId}/preview?size=${width},${height}`;
};
