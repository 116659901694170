import React from 'react';
import PropTypes from 'prop-types';
import IconArrowRight from '@app-images/icon_arrow_right.svg';
import { CircularProgress } from '@material-ui/core';

const styles = {
    div: {
        textAlign: 'left',
        padding: '20px',
    },
};

function WorksBlock({ tasks, isWorkTypesLoaded }) {
    const hasWorks = !!tasks.length;
    return (
        <div className="tabs-content-item faded">
            <div className="info-holder">
                <div className="info-title">
                    {hasWorks ? 'Регламентные работы на объекте' : 'Регламентные работы отсутствуют'}
                </div>
                {isWorkTypesLoaded ? (
                    hasWorks &&
                    tasks.map(({ name }, index) => (
                        <div className="operations-group" key={index}>
                            <div className="operations-group-title">
                                {name}
                                <img src={IconArrowRight} alt="arrow-right" />
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={styles.div}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </div>
    );
}

WorksBlock.defaultProps = {
    tasks: [],
};

WorksBlock.propTypes = {
    tasks: PropTypes.array,
};

export default WorksBlock;
