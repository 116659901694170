import { ActionTypes } from '@app-constants';
import sendRequest from '@app-helpers/ApiRequestHelper/ApiClient';

export const commonNetworkErrorHandler = (dispatch, error) => {
    // console.log('error', error);

    if (error.status === 401) {
        sendRequest(dispatch, {
            method: 'get',
            url: 'user/current',
            showNotifications: false,
            useCommonErrorsHandler: false,
            failCallback: (error) => {
                if (error.status === 401) {
                    dispatch({
                        type: ActionTypes.LOGOUT,
                    });
                }
            },
        });
    }
};
