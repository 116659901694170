import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { allSelector } from '../../actions/notifications';
import Notification from './notification';
class Notifications extends React.Component {
    render() {
        return (
            <div className="notifications">
                <TransitionGroup>
                    {this.props.notifications.map((notification) => (
                        <CSSTransition
                            key={notification.id}
                            component="div"
                            className="notifications"
                            classNames="notification-animation"
                            timeout={{ enter: 150, exit: 50 }}
                        >
                            <Notification notification={notification} delay={5000} />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: allSelector(state),
});

export default connect(mapStateToProps)(Notifications);
