import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, Button } from '@material-ui/core';


const getTerrTypeZones = (value) =>
    !!value ? value.map((it) => it.name.toLowerCase()).join(', ') : 'Не указано';

const TableRowComponent = ({ data, onClick }) => {
    const { id, name, terrTypeZones, terrTypeSurve } = data;
    return (
        <TableRow hover className="table-row body-table" onClick={onClick}>
            <TableCell component="td" scope="row" className="cell double-width">
                {id ?? ''}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {name ?? 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                <div className="td-item-wrapper">{getTerrTypeZones(terrTypeZones)}</div>
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {terrTypeSurve ? terrTypeSurve.name : 'Не указано'}
            </TableCell>
        </TableRow>
    );
};

TableRowComponent.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

export default TableRowComponent;
