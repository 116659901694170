let serviceTableColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
        field: 'name',
        headerName: 'Наименование услуги',
        flex: 4,
        editable: true,
    },
    {
        field: 'terrTypeZones',
        headerName: 'Где выполняется',
        flex: 8,
        editable: true,
        valueGetter: (value) =>
            !!value.value ? value.value.map((it) => it.name.toLowerCase()).join(', ') : 'Не указано',
    },
    {
        field: 'terrTypeSurve',
        headerName: 'Тип покрытия',
        flex: 4,
        editable: true,
        valueGetter: (value) => (value.value ? value.value.name : 'Не указано'),
    },
];

serviceTableColumns = Object.freeze(serviceTableColumns);

export { serviceTableColumns };
