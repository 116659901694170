import React from 'react';

export default function WorksForm(props) {
    return (
        <div class="tabs-content-item faded">
            <div class="b-form">
                <div class="form-wrapper">
                    <div class="row-title">Регламентные работы на объекте</div>
                    <div class="for-copy">
                        <div class="form-row one-column">
                            <div class="form-element">
                                <label for="name" class="form-label">
                                    Наименование*
                                </label>
                                <select id="name" class="custom-select form-input">
                                    <option class="color lightGray" disabled selected label=" ">
                                        Выберите вариант
                                    </option>
                                    <option>Уборка</option>
                                    <option>Полив</option>
                                    <option>Что-то еще</option>
                                </select>
                                <div class="error-message">Ошибка данных</div>
                            </div>
                        </div>
                        <div class="form-row one-column">
                            <div class="form-element">
                                <label for="executor" class="form-label">
                                    Исполнитель*
                                </label>
                                <select id="executor" class="custom-select form-input">
                                    <option class="color lightGray" disabled selected label=" ">
                                        Выберите вариант
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                                <div class="error-message">Ошибка данных</div>
                            </div>
                        </div>
                        <div class="form-row one-column">
                            <div class="form-element">
                                <label for="periodicity" class="form-label">
                                    Периодичность*
                                </label>
                                <select id="periodicity" class="custom-select form-input">
                                    <option class="color lightGray" disabled selected label=" ">
                                        Выберите вариант
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                                <div class="error-message">Ошибка данных</div>
                            </div>
                        </div>
                        <div class="form-row one-column">
                            <div class="form-element">
                                <label for="time" class="form-label">
                                    Время*
                                </label>
                                <select id="time" class="custom-select form-input">
                                    <option
                                        class="color lightGray"
                                        // style="color: red;"
                                        disabled
                                        selected
                                        label=" "
                                    >
                                        Выберите вариант
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                                <div class="error-message">Ошибка данных</div>
                            </div>
                        </div>
                        <div class="form-row one-column">
                            <div class="form-element">
                                <label for="note" class="form-label">
                                    Примечание*
                                </label>
                                <input id="note" class="form-input" placeholder="Введите описание" />
                                <div class="error-message">Ошибка данных</div>
                            </div>
                        </div>
                    </div>
                    <div class="add-button-holder">
                        <a href="#" class="g-button white with-border add-button">
                            Добавить вид работ
                        </a>
                    </div>
                </div>
                <div class="controls-wrapper">
                    <div class="form-row controls-holder">
                        <button class="g-button white with-border cancel-button">Отменить</button>
                        <button class="g-button" type="submit">
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
