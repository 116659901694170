import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsError } from '@app-redusers';
import { auth } from '@app-actions';
import { ErrorSnackbar } from '@app-universal/notifications/CustomizedSnackbars';
import { CheckboxInput } from '@app-universal/form/inputs/CheckboxInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import RoutePath from '@app-universal/routing/RoutePath';

class LoginForm extends React.Component {
    static propTypes = {
        // проверка типов пропсов
        isError: PropTypes.bool.isRequired,
    };

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            rememberMe: false,
            authError: false,
            showPassword: false,
        };
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoginError = this.handleLoginError.bind(this);
        this.handleErrorMessageClose = this.handleErrorMessageClose.bind(this);
    }

    handleChangeCheckBox(evt) {
        this.setState({ [evt.target.name]: evt.target.checked });
        this.setState({ authError: false });
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax

        this.setState({ [evt.target.name]: evt.target.value });
        this.setState({ authError: false });
    }

    handleSubmit(event) {
        console.log('handleSubmit', event);
        event.preventDefault();
        this.setState({ authError: false });
        this.props.dispatch(auth(this.state.email, this.state.password, this.state.rememberMe, this.handleLoginError));
    }

    handleLoginError(event) {
        console.log('handlr error!');
        this.setState({ authError: true });
    }

    handleErrorMessageClose(event) {
        this.setState({ authError: false });
    }

    render() {
        return (
            <div className="b-form">
                <form noValidate onSubmit={this.handleSubmit}>
                    {/* <CustomizedSnackbars /> */}
                    <ErrorSnackbar
                        open={this.state.authError}
                        closeHandler={this.handleErrorMessageClose}
                        message="Ошибка авторизации: Неверное имя пользователя или пароль!"
                    />

                    <div className="form-row">
                        <div className="form-element">
                            <InputLabel for='email'>Email</InputLabel>
                            <TextField
                                error={this.state.authError}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={this.handleChange}
                                placeholder="Введите логин"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-element">
                            <InputLabel for='password'>Пароль</InputLabel>
                            <TextField
                                error={this.state.authError}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                onChange={this.handleChange}
                                placeholder="Введите пароль"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                // aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPassword ?
                                                    <VisibilityOff style={{transform: 'scale(0.47)', fill: '#000000'}}/> : <Visibility style={{ transform: 'scale(0.47)', fill: '#000000'}}/>
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-element">
                            <CheckboxInput
                                value={this.state.rememberMe}
                                onChange={(e) => this.handleChangeCheckBox(e)}
                                name="rememberMe"
                                label="Запомнить меня"
                            />
                        </div>
                    </div>
                    <div className="form-row button-holder">
                        <div className="form-element button-login">
                            {
                                <Button id="login-button" type="submit" variant="contained" color="primary" style={{padding: '9.5px 60.5px'}}>
                                    Войти
                                </Button>
                            }
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-element">
                            <Link className='g-button white guest-login' to={RoutePath('map')}>
                                Войти как гость
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect((state) => ({
    isError: getIsError(state),
}))(LoginForm);
