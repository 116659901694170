import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleTabs from '@app-universal/navigation/SimpleTabs';
import InfoForm from './includes/InfoForm';
import WorksForm from './includes/WorksForm';
import FromStateHelper from '@app-universal/form/FromStateHelper';
import { createObject } from '@app-actions';
import { districtList } from './testData/districtList';
import { id6993List } from './testData/id6993List';
import { surfaceTypeList } from './testData/surfaceTypes';
import { territoryTypeList } from './testData/territoryTypeList';

export default function ObjectCreate({ toggleSidebar, reloadList }) {
    const dispatch = useDispatch();
    const [state, setState] = useState({ backendValidationErrors: {}, lockSubmit: false });
    const objectStateHelper = FromStateHelper(
        {
            group: '',
            class: '',
            district: '',
            ID_6993: '',
            len_6993: '',
            address: '',
            addressFrom: '',
            addressTo: '',
            type_zone: '',
            type_surve: '',
            area: '',
            len_curb: '',
            len_nocurb: '',
        },
        {}
    );
    const { formState, setFormState, getFormState } = objectStateHelper;

    const handleSubmit = (event) => {
        event.preventDefault();
        setState((pr) => ({ ...pr, lockSubmit: true }));
        const oldState = {...getFormState()};
        let dataToSend = {
            ...oldState,
            district: districtList.find((it) => it.value === oldState.district).label,
            ID_6993: id6993List.find((it) => it.value === oldState.ID_6993).label,
            type_zone: territoryTypeList.find((it) => it.value === oldState.type_zone).label,
            type_surve: surfaceTypeList.find((it) => it.value === oldState.type_surve).label,
            area: parseFloat(oldState.area),
            len_curb: parseFloat(oldState.len_curb),
            len_nocurb: parseFloat(oldState.len_nocurb),
        };

        dispatch(
            createObject(
                dataToSend,
                () => {
                    toggleSidebar();
                    reloadList();
                },
                () => setState((pr) => ({...pr, lockSubmit: false}))
            )
        );
    };
    const handleFail = () => {};

    const tabsContent = [
        <InfoForm
            backendValidationErrors={state.backendValidationErrors}
            onSubmit={handleSubmit}
            onError={handleFail}
            disableForm={state.lockSubmit}
            formState={formState}
            handleChangeSelect={objectStateHelper.handleChangeInt}
            handleChangeText={objectStateHelper.handleChangeText}
            toggleSidebar={toggleSidebar}
        />,
        <WorksForm toggleSidebar={toggleSidebar} />,
    ];

    const tabsLabels = ['Информация', 'Работы'];

    return (
        <div class="edit-modals-holder">
            <div class="b-modal-edit">
                <div class="modal-content-wrapper">
                    <div class="modal-header ">
                        <div class="title">Улица Красных Коммунаров 56-175</div>
                    </div>
                    <div class="modal-body">
                        <SimpleTabs tabsLabels={tabsLabels} tabsContent={tabsContent} />
                    </div>
                </div>
            </div>
        </div>
    );
}
