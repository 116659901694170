import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
}));

export default function AppLoaderView() {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{
                minHeight: '100vh',
                backgroundColor: 'lightgray',
            }}
        >
            <Grid item xs={3}>
                <Typography variant="h6" color="textSecondary">
                    ITT Краснодар
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <CircularProgress className={classes.progress}/>
            </Grid>

            <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                    Пожалуйста, подождите.
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                    Выполняется инициализация приложения....
                </Typography>
            </Grid>
        </Grid>
    );
}
