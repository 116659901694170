import React from 'react';
import queryString from 'query-string';
import { getUserList } from '@app-actions';
import UserListGrid from './UserListGrid';
import EntityList from '@app-universal/list/EntityList';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import UserCreate from '../UserCreate/UserCreate';

const routes = [
    {
        label: 'Управление доступом',
        link: 'access-control',
        disabled: true,
    },
    {
        label: 'Пользователи',
        link: 'users',
    },
];

function UserList(props) {
    const itemsListComponentCallback = React.useCallback((listState, paginationState, setPaginationState, Tools) => {
        return (
            <UserListGrid
                listState={listState}
                paginationState={paginationState}
                setPaginationState={setPaginationState}
                reloadList={Tools.reloadListCallback}
                paginationState={paginationState}
                setPaginationState={setPaginationState}
            />
        );
    }, []);

    const listFilterComponentCallback = React.useCallback((props) => {
        const { filterFSH, getFilterParams, clearFilter, listState, toggleSidebar } = props;
        const submit = () => {
            toggleSidebar();
        };
        return (
            <>
                <div className="wrapper">Это фильтр</div>
                <button onClick={submit}>submit</button>
            </>
        );
    }, []);

    const primaryBtnComponentCallback = React.useCallback(({ filterState, listState, Tools }) => {
        const disabled = !listState.downloadingComplete || !listState.items.length;
        return (
            <SidebarActivator
                render={(toggleSidebar) => (
                    <PrimaryButton disabled={disabled} onClick={toggleSidebar} title="Добавить пользователя" />
                )}
                renderContent={(toggleSidebar) => (
                    <UserCreate toggleSidebar={toggleSidebar} reloadList={Tools.reloadListCallback} />
                )}
            />
        );
    }, []);

    const itemCreateComponentCallback = React.useCallback((props) => {
        const { toggleSidebar, filterFSH, listState, Tools } = props;

        return <div>Создание пользователя</div>;
    }, []);

    const defaultFilterState = React.useMemo(() => {
        return {
            query: '',
            services: [],
        };
    }, []);

    return (
        <section className="b-users-page">
            <div className="g-container">
                <EntityList
                    itemsListComponentCallback={itemsListComponentCallback}
                    listFilterComponentCallback={listFilterComponentCallback}
                    primaryBtnComponentCallback={primaryBtnComponentCallback}
                    itemCreateComponentCallback={itemCreateComponentCallback}
                    pageTitle="Пользователи"
                    loadItemsListCallback={getUserList}
                    defaultFilterState={defaultFilterState}
                    routes={routes}
                    showFilter={false}
                    initialItemsPerPage={50}
                />
            </div>
        </section>
    );
}

export default UserList;
