import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser, getAndSetUserData } from '@app-actions';
import { getCurrentUser } from '@app-redusers';
import FromStateHelper from '@app-universal/form/FromStateHelper';
import { getFullName } from '../userHelpers';
import UserEditSelfForm from './UserEditSelfForm';

const UserEditSelf = ({ toggleSidebar }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);

    const [state, setState] = useState({ backendValidationErrors: {}, lockSubmit: false });
    const userStateHelper = FromStateHelper(
        {
            email: currentUser.email,
            firstName: currentUser.firstName,
            phone: currentUser.phone,
            secondName: currentUser.secondName,
            surname: currentUser.surname,
            plainPassword: '',
        },
        {}
    );
    const { formState, setFormState, getFormState } = userStateHelper;

    const handleReset = () => toggleSidebar();
    const handleSubmit = (event) => {
        event.preventDefault();
        setState((pr) => ({ ...pr, lockSubmit: true }));

        dispatch(
            updateCurrentUser(
                formState,
                () => {
                    dispatch(getAndSetUserData());
                    toggleSidebar();
                },
                () => setState((pr) => ({ ...pr, lockSubmit: false }))
            )
        );
    };
    const handleFail = () => {};

    return (
        <UserEditSelfForm
            firstName={formState.firstName}
            surname={formState.surname}
            secondName={formState.secondName}
            phone={formState.phone}
            email={formState.email}
            plainPassword={formState.plainPassword}
            onCancel={handleReset}
            onSubmit={handleSubmit}
            onError={handleFail}
            fullName={getFullName(formState.firstName, formState.surname)}
            handleChangeText={userStateHelper.handleChangeText}
            disableForm={state.lockSubmit}
            backendValidationErrors={state.backendValidationErrors}
        />
    );
};

UserEditSelf.propTypes = {
    toggleSidebar: PropTypes.func,
};

export default UserEditSelf;
