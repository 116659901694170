import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ObjectView from './ObjectView/ObjectView';
import ObjectEdit from './ObjectEdit/ObjectEdit';
import { getObject } from '@app-actions';
import { CircularProgress } from '@material-ui/core';


const styles = {
    loaderFilterBlock: {
        textAlign: 'center',
        marginTop: '10%',
        minWidth: '450px'
    },
};

/**
 * @TODO
 * Временный компонент-дубликат.
 * Отличается от ObjectIndex, тем, что принимает не id сущности, чтобы потом вытащить
 * данные по апи, а уже готовые данные
 */
export default function ObjectIndexDuplicate({ shouldShowForm, toggleSidebar, objectData, id, ...rest }) {
    console.log('------------------', shouldShowForm);
    
    const [isEditMode, setIsEditMode] = useState(shouldShowForm)
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [data, setData] = useState(objectData)
    console.log('myyyyyyyyyyyyyyyy', data);
    

    // const [state, setState] = useState({
    //     data: objectData,
    //     showForm: shouldShowForm,
    // });
    const dispatch = useDispatch();

    const toggleMode = () => {
        console.log('toggleMode function!')
        if (isEditMode) {
            setIsDataLoaded(false);
            dispatch(getObject(id, handleSuccessEntityLoad));
        }
        setIsEditMode((prev) => !prev)
        // setState((prev) => ({ ...prev, showForm: !prev.showForm }));
    };

    const handleSuccessEntityLoad = (response) => {
        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeee');
        console.log(isDataLoaded);
        
        setData((prev) => ({ ...prev, ...response }))
        
        setIsDataLoaded(true)
        // setState((pr) => ({ ...pr, isItemDataLoaded: true, data: { ...pr.data, ...response } }));
    };

    useEffect(() => {
        dispatch(getObject(id, handleSuccessEntityLoad));
    }, []);

    // useEffect(() => {
    //     // console.log('state or state.data changed, state.data from ObjectIndexDuplicate component')
    //     // console.log(state.data)
    // }, [state.data, state])

    return (isDataLoaded ? (isEditMode ? (
        <ObjectEdit objectData={data} toggleMode={toggleMode} />
    ) : (
        <ObjectView
            toggleMode={toggleMode}
            isFromIframe={true}
            ID_6993={data.ID_6993}
            address={data.address}
            area={data.area}
            objectClass={data.class}
            district={data.district}
            group={data.group}
            id={id}
            len_6993={data.len_6993}
            len_curb={data.len_curb}
            len_nocurb={data.len_nocurb}
            type_serv={data.type_serv}
            type_surve={data.type_surve}
            type_zone={data.type_zone}
            geometryAsGeojson={data.geometryAsGeojson}
            tasks={data.tasks}
            showLinkToMap={false}
            showEditIcon={true}
        />
    )) : (<div style={styles.loaderFilterBlock}>
            <CircularProgress />
        </div>));
}

ObjectIndexDuplicate.defaultProps = {
    shouldShowForm: false,
};

ObjectIndexDuplicate.propTypes = {
    shouldShowForm: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func,
    objectData: PropTypes.object,
};
