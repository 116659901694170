import sendRequest from '@app-helpers/ApiRequestHelper/ApiClient';

export const getPassportList =
    (successCallback, params = null) =>
    async (dispatch) => {
        await sendRequest(dispatch, {
            method: 'get',
            url: 'passport',
            data: params,
            successCallback: successCallback,
        });
    };

export const getAllPasports =
    (successCallback) =>
    async (dispatch) => {
        await sendRequest(dispatch, {
            method: 'get',
            url: 'passport/all',
            successCallback: successCallback,
        });
    };

export const getPassport = (id, successCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'get',
        url: 'passport/' + id,
        successCallback: successCallback,
    });
};
