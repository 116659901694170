import { notifications } from '../constants/notifications';

const initialState = Object.freeze({
    all: {},
    ids: [],
});

export default (state = initialState, action) => {
    const { id, kind, message } = action;
    switch (action.type) {
        case notifications.SHOW:
            const prevAll = state.all;
            let present = Object.keys(prevAll)
                .map((key) => {
                    return prevAll[key].kind === (kind === 'failure' ? 'success' : 'failure') ? key : null;
                })
                .pop();

            let ids = [id];
            let all = { [id]: { kind, message } };

            if (present) {
                ids.unshift(present);
                all[present] = prevAll[present];
            }
            return { ...state, all: all, ids: ids };
        case notifications.HIDE:
            const idIndex = state.ids.indexOf(id);
            if (idIndex === -1) {
                return {
                    ...state,
                };
            }
            const nextIds = [...state.ids.slice(0, idIndex), ...state.ids.slice(idIndex + 1)];
            const { [id]: value, ...rest } = state.all;
            return {
                ...state,
                all: {
                    ...rest,
                },
                ids: nextIds,
            };
        case notifications.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
