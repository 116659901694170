import React from 'react';
import PropTypes from 'prop-types';

import MainProgress from './MainProgress';

function MainProgressWrapper(props) {
    return props.isInited ? props.children : <MainProgress text={props.text} />;
}

MainProgressWrapper.propTypes = {
    isInited: PropTypes.bool,
    text: PropTypes.string,
};

export default MainProgressWrapper;
