const SHOW = '@notifications/SHOW';
const HIDE = '@notifications/HIDE';
const RESET = '@notifications/RESET';
let notifications = {
    SHOW: '@notifications/SHOW',
    HIDE: '@notifications/HIDE',
    RESET: '@notifications/RESET'
};


export { notifications };
