import ApiRequestHelper from './ApiRequestHelper';
import { baseApiUrl } from '../clientConfig.js';
import jswl from 'js-wrapper-lib';
import { show as showNotification } from '@app-actions';
import { showMainProgress, hideMainProgress } from '@app-helpers/progress';
import { commonNetworkErrorHandler } from './commonNetworkErrorHandler';

const ApiRequest = new ApiRequestHelper(baseApiUrl);

const defaultOptions = {
    method: 'get',
    url: '',
    data: {},
    successCallback: () => {},
    failCallback: () => {},
    useMainProgress: true,
    showNotifications: true,
    successMessage: '',
    headers: {},
    requestDataType: 'json',
    responseDataType: 'json',
    useCommonErrorsHandler: true, //  для всех запросов, кроме того, по которому тестируем авторзиацию
};

const sendRequest = async (dispatch, options) => {
    const settings = {
        ...defaultOptions,
        ...options,
    };

    const {
        method,
        url,
        data,
        headers,
        successCallback,
        failCallback,
        useMainProgress,
        showNotifications,
        requestDataType,
        responseDataType,
        successMessage,
        useCommonErrorsHandler,
    } = settings;

    let requestResult = null;

    try {
        if (useMainProgress) {
            showMainProgress(dispatch);
        }

        requestResult = await ApiRequest.send(
            method.toUpperCase(),
            url,
            data,
            requestDataType,
            responseDataType,
            headers
        );

        const response = requestResult;

        if (!jswl.isEmpty(successMessage)) {
            showNotification(dispatch, { message: successMessage, kind: 'success' });
        }

        successCallback(response, data);

        // console.log('Параметры запроса:',data)
        console.log('Получен ответ:', response);

        if (useMainProgress) {
            hideMainProgress(dispatch);
        }
    } catch (error) {
        console.log(error);

        if (showNotifications) {
            const { responseJSON } = error;
            // showNotification(dispatch, { message: responseJSON && responseJSON.message || 'Ошибка загрузки данных', kind: 'failure' });
            showErrorMessage(dispatch, responseJSON);
        }
        if (useMainProgress) {
            hideMainProgress(dispatch);
        }
        failCallback(error);

        if (useCommonErrorsHandler) {
            commonNetworkErrorHandler(dispatch, error);
        }
    }

    return Promise.resolve(requestResult);
};

const showErrorMessage = (dispatch, errorData) => {
    let mainText = (errorData && errorData.message) || 'Ошибка загрузки данных';

    let additionalText = errorData && !jswl.isEmpty(errorData.data) ? Object.values(errorData.data)[0].message : '';

    showNotification(dispatch, {
        message: mainText + ' // ' + additionalText,
        kind: 'failure',
    });
};

export default sendRequest;
