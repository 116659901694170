import React from 'react';
import PropTypes from 'prop-types';
import { getFullName, getRoleName } from '../userHelpers';
import IconEditSmall from '@app-images/icon_edit_small.svg';
import IconFace from '@app-images/icon_face.svg';
import IconPhone from '@app-images/icon_phone.svg';
import IconMail from '@app-images/icon_mail.svg';
import IconDelete from '@app-images/icon_delete.svg';

const UserCell = ({ showForm, showCard, userData, number, isEditable, removeUser }) => {
    const { id, type, firstName, surname, email, phone } = userData;
    const fullName = getFullName(firstName, surname);

    const showFormWrap = (e) => {
        e.stopPropagation();
        showForm();
    };

    const removeUserHandler = (e) => {
        e.stopPropagation();
        const confirm = window.confirm(`Вы действительно хотите удалить пользователя ${fullName}`);
        if (confirm) removeUser(id);
    };

    return (
        <div className="users-item" onClick={showCard}>
            <div className="users-item-inner">
                <div className="item-header">
                    <div className="item-header-left">
                        <span className="number">{number}</span>
                        <span className="role">{getRoleName(type)}</span>
                    </div>
                    {isEditable && (
                        <div className="item-header-right">
                            <div className="edit-user" onClick={showFormWrap}>
                                <img className="icon" src={IconEditSmall} alt="icon-edit-small" />
                            </div>
                            <div className="remove-user" onClick={removeUserHandler}>
                                <img className="icon" src={IconDelete} alt="icon-delete" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="name">{fullName}</div>
                <div className="contacts-holder">
                    <a href="#" className="contacts-item">
                        <img className="icon" src={IconFace} alt="icon-face" />
                        {email || 'Не указан'}
                    </a>
                    <a href={phone ? `tel: ${phone}` : '#'} className="contacts-item">
                        <img className="icon" src={IconPhone} alt="" />
                        {phone || 'Не указан'}
                    </a>
                    <a href={email ? `mailto: ${email}` : '#'} className="contacts-item">
                        <img className="icon" src={IconMail} alt="icom-mail" />
                        {email || 'Не указан'}
                    </a>
                </div>
            </div>
        </div>
    );
};

UserCell.propTypes = {
    showCard: PropTypes.func,
    showForm: PropTypes.func,
    userData: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    isEditable: PropTypes.bool.isRequired,
    removeUser: PropTypes.func,
};

export default UserCell;
