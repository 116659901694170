import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { formatLength } from '@app-helpers/formatters';

const styles = {
    div: {
        maxWidth: '325px',
        textAlign: 'center',
        padding: '20px',
    },
};

const PassportDataBlock = ({ name, toponym, adm_district, road_id, category, len, blen, hblen, isLoading }) => {
    return (
        <div className="tabs-content-item faded">
            <div className="info-holder with-border">
                <div className="info-title">Паспорт объекта</div>
                {isLoading ? (
                    <div style={styles.div}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className="info-group">
                            <div className="info-group-title">Наименование</div>
                            <div className="info-group-value">
                                {toponym} {name || 'Не указано'}
                            </div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Административн(ый/ые) округ(а)</div>
                            <div className="info-group-value">
                                {adm_district?.length ? adm_district.map((it) => it.name).join(', ') : 'Не указан'}
                            </div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Идентификатор</div>
                            <div className="info-group-value">{road_id ?? 'Не указан'}</div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Класс территории</div>
                            <div className="info-group-value">{category ?? 'Не указан'}</div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Протяженность</div>
                            <div className="info-group-value">{len ? formatLength(len) : 'Не указана'}</div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Протяженность проезжих частей с бордюром</div>
                            <div className="info-group-value">{blen ? formatLength(blen, 2) : 'Не указана'}</div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Протяженность проезжих частей без бордюров</div>
                            <div className="info-group-value">{hblen ? formatLength(hblen, 2) : 'Не указана'}</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PassportDataBlock;
