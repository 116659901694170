import React from 'react';
import Pagination from '@app-universal/table/Pagination';
import TableComponent from './TableComponent';

const SurfaceListTable = (props) => {
    const { listState, paginationState, setPaginationState } = props;

    return (
        <>
            <div className="table-holder" style={{ width: '100%' }}>
                <TableComponent
                    surfaces={listState.items}
                    loading={!listState.downloadingComplete}
                />
            </div>
            {listState.items.length ? (
                <Pagination paginationState={paginationState} setPaginationState={setPaginationState} />
            ) : null}
        </>
    );
};

export default SurfaceListTable;
