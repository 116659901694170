import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, getIsUserAuthed } from '@app-redusers';
import NavigationDropdown from './NavigationDropdown';
import { routes } from '@app-constants';
import LogoImg from '@app-images/icon_logotype.png';
import { Link, NavLink } from 'react-router-dom';
import RoutePath from '@app-universal/routing/RoutePath';
import HeaderMenu from './HeaderMenu';
import { logoutUser } from '@app-actions';
import jswl from 'js-wrapper-lib';

const hasAccess = (userRoles, routeRoles) => jswl.arr.isAnyInArray(userRoles, routeRoles);

function Header() {
    const currentUser = useSelector(getCurrentUser);
    const authed = useSelector(getIsUserAuthed);
    const dispatch = useDispatch();

    const logoutCallback = () => {
        dispatch(logoutUser());
    };

    return (
        <section className="b-header">
            <div className="g-container header">
                <div className="header-wrapper">
                    <div className="header-left">
                        <div className="logotype-holder">
                            <img className="icon-logotype" src={LogoImg} alt="" />
                        </div>
                        <ul className="nav-menu">
                            {routes.map((it, index) => {
                                if (it.isPrivate && !authed) {
                                    return '';
                                }
                                if (authed) {
                                    if (!hasAccess(currentUser.roles, it.roles)) {
                                        return '';
                                    }
                                }
                                return it.children ? (
                                    <NavigationDropdown
                                        items={it.children}
                                        label={it.label}
                                        link={it.link}
                                        key={index}
                                        isMainCategoryClickable={it.isMainCategoryClickable}
                                    />
                                ) : (
                                    <li key={index} className="nav-menu-item">
                                        <span>
                                            <NavLink
                                                to={RoutePath(it.link)}
                                                className="nav-menu-item-link"
                                                activeClassName="active"
                                            >
                                                {it.label}
                                            </NavLink>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="header-right">
                        {authed && <HeaderMenu currentUser={currentUser} logoutCallback={logoutCallback} />}
                        {!authed && <div className="login-holder"><Link to={RoutePath('login')}>Войти</Link></div>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;
