import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import { tableColumns, testServices } from '@app-constants';
import ObjectIndex from '@app-pages/Object/ObjectIndex';
import Pagination from '@app-universal/table/Pagination';
import { makeStyles } from '@material-ui/styles';
import NoRowsOverlay from '@app-universal/table/NoRowsOverlay';
import LoadingOverlay from '@app-universal/table/LoadingOverlay';
import TableComponent from './TableComponent';

const useStyles = makeStyles(() => ({
    'MuiDataGrid-menuIcon': {
        display: 'none',
    },
}));

const ObjectListTable = (props) => {
    const { listState, paginationState, setPaginationState } = props;
    const [state, setState] = useState({ activeItem: null });
    const classes = useStyles();

    const rowClickHandler = (params, e, toggleSidebar) => {
        setState((pr) => ({ ...pr, activeItem: params }));
        toggleSidebar();
    };
    return (
        <>
            <div className="table-holder" style={{ width: '100%' }}>
                <SidebarActivator
                    render={(toggleSidebar) => (
                        <TableComponent
                            objects={listState.items}
                            toggleSidebar={toggleSidebar}
                            rowClickHandler={rowClickHandler}
                            loading={!listState.downloadingComplete}
                        />
                    )}
                    renderContent={(toggleSidebar) => (
                        <ObjectIndex objectId={state.activeItem?.id} toggleSidebar={toggleSidebar} />
                    )}
                />
            </div>
            {listState.items.length ? (
                <Pagination paginationState={paginationState} setPaginationState={setPaginationState} />
            ) : null}
        </>
    );
};

export default ObjectListTable;
