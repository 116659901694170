import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { formatLength, formatSquare } from '@app-helpers/formatters';
import {useLocation} from "react-router-dom";

const TableRowComponent = ({ data, onClick }) => {
    const { adm_district, adm_reg, area, blen, category, gid, hblen, len, name, road_id, toponym, address } = data;
    const location = useLocation();
    const isUds = location.pathname.includes('passport/list');
    return (
        <TableRow hover className="table-row body-table" onClick={onClick}>
            <TableCell component="td" scope="row" className="cell double-width">
                {name ?? 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {toponym ?? 'Не указан'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {road_id ?? 'Не указан'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {adm_district && adm_district.length ? adm_district.map((it) => it.name).join(', ') : 'Не указан'}
            </TableCell>
            {isUds && (
                <>
                    <TableCell component="td" scope="row" className="cell double-width">
                        {category ?? 'Не указана'}
                    </TableCell>
                    <TableCell align="left" component="td" scope="row" className="cell double-width">
                        {len ? formatLength(len) : 'Не указана'}
                    </TableCell>
                </>
            )}
            <TableCell align="left" component="td" scope="row" className="cell double-width">
                {area ? formatSquare(area): 'Не указана'}
            </TableCell>
            {!isUds && (
                <>
                    <TableCell component="td" scope="row" className="cell double-width">
                        {address ?? 'Не указан'}
                    </TableCell>
                </>
            )}
            {isUds && (
                <>
                    <TableCell align="left" component="td" scope="row" className="cell double-width">
                        {blen ? formatLength(blen) : 'Не указана'}
                    </TableCell>
                    <TableCell align="center" component="td" scope="row" className="cell double-width">
                        {hblen ? formatLength(hblen) : 'Не указана'}
                    </TableCell>
                </>
            )}
        </TableRow>
    );
};

TableRowComponent.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

export default TableRowComponent;
