import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import RoutePath from '@app-universal/routing/RoutePath';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState, bindHover, bindPopover } from 'material-ui-popup-state/hooks';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        display: 'block',
        width: '100%',
        padding: 0,
        fontSize: 'inherit',
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const StyledListItemText = withStyles((theme) => ({
    primary: {
        padding: '12px 19px',
        fontSize: '0.8em',
        lineHeight: '1.5',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    root: {
        margin: 0,
    },
}))(ListItemText);

const StyledPopover = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
    },
    paper: {
        paddingTop: 28,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible !important',
    },
}))(HoverPopover);

export default function NavigationDropdown(props) {
    const { items, label, link, isMainCategoryClickable } = props;
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoPopover',
    });

    return (
        <li className="nav-menu-item">
            <span {...bindHover(popupState)}>
                {isMainCategoryClickable ? (
                    <NavLink to={RoutePath(link)} className="nav-menu-item-link" activeClassName="active">
                        {label}
                    </NavLink>
                ) : (
                    <span className="nav-menu-item-link">{label}</span>
                )}
            </span>
            <StyledPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ul className="dropdown-wrapper">
                    {items?.map((it, index) => {
                        return (
                            <StyledMenuItem key={index}>
                                <NavLink
                                    to={RoutePath(it.link)}
                                    className="nav-menu-item-link"
                                    activeClassName="active"
                                >
                                    <StyledListItemText primary={it.label} />
                                </NavLink>
                            </StyledMenuItem>
                        );
                    })}
                </ul>
            </StyledPopover>
        </li>
    );
}

NavigationDropdown.defaultProps = {
    isMainCategoryClickable: true,
};

NavigationDropdown.propTypes = {
    items: PropTypes.array,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isMainCategoryClickable: PropTypes.bool,
};
