import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RoutePath from './RoutePath';
import { getHasAnyRole } from '../../../reducers/index.js';
import { connect } from 'react-redux';
import jswl from 'js-wrapper-lib';

const PrivateRoute = ({ component: Component, isAuthed, roles, hasAnyRole, ...rest }) => {
    const hasAccess = jswl.isEmpty(roles) || hasAnyRole(roles);

    // console.log(' hasAccess',  hasAccess);
    // console.log(' isAuthed',  isAuthed);
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthed ? (
                    hasAccess ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: RoutePath('index'),
                                state: { from: props.location },
                            }}
                        />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: RoutePath('login'),
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default connect((state) => ({
    hasAnyRole: getHasAnyRole(state),
}))(PrivateRoute);
