import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

export default function PrimaryButton(props) {
    const { title, onClick, ...rest } = props;
    return (
        <Button className="g-button create-territory" onClick={onClick} variant="contained" color="primary" {...rest}>
            {title}
        </Button>
    );
}

PrimaryButton.defaultProps = {
    onClick: () => {},
};

PrimaryButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
