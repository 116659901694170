import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './universal/routing/PrivateRoute';
import RoutePath from './universal/routing/RoutePath';

import AppInitControl from './pages/common/AppInitControl';
import { connect } from 'react-redux';
import { getIsUserAuthed, getIsAppInited, getIsSuperAdmin } from '../reducers/index.js';
import { UserRoles } from '@app/constants/userRoles';

import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './pages/common/Header/Header';

import { theme, styles } from '../styles/app';
import { ActionTypes } from '../constants';
import Notifications from './notifications';

import IndexPage from '@app-pages/index';
import Login from '@app-pages/Login/Login';
import NotFoundPage from '@app-pages/NotFoundPage/NotFoundPage';
import ObjectList from '@app-pages/Object/ObjectList/ObjectList/ObjectList';
import UserList from '@app-pages/User/UserList/UserList';
import CadastralMap from '@app-pages/CadastralMap/CadastralMap';
import ServiceList from '@app-pages/Service/ServiceList/ServiceList';
import SurfaceList from '@app-pages/Surface/SurfaceList/SurfaceList';
import ZoneList from '@app-pages/Zone/ZoneList/ZoneList';
import PassportList from '@app-pages/Passport/PassportList/PassportList';

function App(props) {
    const [, forceUpdate] = React.useState();

    const [state, setState] = React.useState({
        mobileOpen: false,
    });

    const handleDrawerToggle = React.useCallback(() => {
        setState((state) => ({ ...state, mobileOpen: !state.mobileOpen }));
    }, [state.mobileOpen]);

    React.useEffect(() => {
        props.dispatch({
            type: ActionTypes.SET_CURRENT_LOCATION,
            location: props.location.pathname + props.location.search,
        });
    }, [props.location.pathname]);

    const classes = props.classes;
    window.do = () => forceUpdate();
    const authed = props.isUserAuthed;
    const isSuperAdmin = props.isSuperAdmin;
    const isOnLoginPage = props.location.pathname === RoutePath('login');

    return (
        <>
            {!props.isAppInited ? (
                <AppInitControl />
            ) : (
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <Notifications />
                        <div className={classes.appContent}>
                            {!isOnLoginPage && <Header onDrawerToggle={handleDrawerToggle} />}
                            <main className={classes.mainContent}>
                                <Switch>
                                    <Redirect exact from="/" to={RoutePath('index')} />

                                    <Route path={RoutePath('login')} component={Login} />

                                    <PrivateRoute
                                        path={RoutePath('index')}
                                        component={IndexPage}
                                        isAuthed={authed}
                                        roles={[]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('object/list')}
                                        component={ObjectList}
                                        isAuthed={authed}
                                        roles={[]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('passport/list')}
                                        component={PassportList}
                                        isAuthed={authed}
                                        roles={[]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('recreation/list')}
                                        component={PassportList}
                                        isAuthed={authed}
                                        roles={[]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('handbooks/service/list')}
                                        component={ServiceList}
                                        isAuthed={authed}
                                        roles={[UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('handbooks/surface/list')}
                                        component={SurfaceList}
                                        isAuthed={authed}
                                        roles={[UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('handbooks/zone/list')}
                                        component={ZoneList}
                                        isAuthed={authed}
                                        roles={[UserRoles.superAdmin.valueStr, UserRoles.specialist.valueStr]}
                                    />
                                    <PrivateRoute
                                        path={RoutePath('users')}
                                        component={UserList}
                                        isAuthed={authed}
                                        roles={[UserRoles.superAdmin.valueStr]}
                                    />

                                    <Route path={RoutePath('map')} component={CadastralMap} />
                                    <Route path="*" exact={true} component={NotFoundPage} roles={[]} />
                                </Switch>
                            </main>
                        </div>
                    </div>
                </ThemeProvider>
            )}
        </>
    );
}

export default connect((state) => ({
    isUserAuthed: getIsUserAuthed(state),
    isAppInited: getIsAppInited(state),
    isSuperAdmin: getIsSuperAdmin(state),
}))(withStyles(styles)(App));
