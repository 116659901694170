import jswl from 'js-wrapper-lib';
import { UserRoles } from '../constants/userRoles';

export const getIsUserAuthed = (state) => state.isUserAuthed;
export const getIsAppInited = (state) => state.isAppInited;
export const getIsError = (state) => state.isError;
export const getUserName = (state) => state.userName;
export const getNeedShowMainProgressBar = (state) => state.showMainProgress;
export const getIsEmailConfirmed = (state) => state.isEmailConfirmed;

export const getCurrentUser = (state) => state.currentUser;

export const getIsSuperAdmin = (state) => {
    return state.userRoles.includes(UserRoles.superAdmin.valueStr);
};

export const getHasAnyRole = (state) => (roles) => {
    return jswl.arr.isAnyInArray(roles, state.userRoles);
};
