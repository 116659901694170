import React from 'react';
import SimpleTabs from '@app-universal/navigation/SimpleTabs';
import ExplicationBlock from './includes/ExplicationBlock';
import PassportDataBlock from './includes/PassportDataBlock';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import { Link } from 'react-router-dom';
import RoutePath from '@app-universal/routing/RoutePath';
import { makeStyles } from '@material-ui/core/styles';
import { baseApiUrl } from '@app-helpers/clientConfig';

const useStyles = makeStyles((theme) => ({
    btnHold: {
        display: 'flex',
    },
    backBtn: {
        border: '1px solid #2e3e67',
        padding: '9.5px 14px',
        maxWidth: '168px',
        fontSize: '14px',
        lineHeight: '1.21',
        margin: '0 auto',
        backgroundColor: '#ffffff',
        color: '#2e3e67',
    },
    printBtn: {
        border: '1px solid #2e3e67',
        padding: '9.5px 14px',
        maxWidth: '168px',
        fontSize: '14px',
        lineHeight: 1.21,
        margin: '0 auto 0 0.5rem',
    },
}));

const tabsLabels = ['Паспорт', 'Экспликация'];

const PassportView = ({
    name,
    toponym,
    adm_district,
    adm_okr,
    road_id,
    category,
    len,
    blen,
    hblen,
    explication,
    secondaryBtnTitle,
    secondaryBtnLink,
    isItemDataLoaded,
    geometryAsText,
    passportId,
    preview,
}) => {
    const classes = useStyles();
    const tabsContent = [
        <PassportDataBlock
            name={name}
            toponym={toponym}
            adm_district={adm_district}
            adm_okr={adm_okr}
            road_id={road_id}
            category={category}
            len={len}
            blen={blen}
            hblen={hblen}
            isLoading={!isItemDataLoaded}
        />,
        <ExplicationBlock explication={explication} isLoading={!isItemDataLoaded} />,
    ];

    // const preview2 = 'https://giskr.it-thematic.ru/api/resource/237/feature/22823/preview?size=600,200';

    return (
        <div className="info-modals-holder">
            <div className="b-modal-info" data-modal>
                <div className="modal-content-wrapper">
                    <div className="modal-header ">
                        <div className="header-left">
                            <div className="title">Просмотр информации</div>
                        </div>
                        <div className="header-right">
                            <div className={classes.btnHold}>
                                <Link
                                    to={{ pathname: RoutePath(secondaryBtnLink), state: { geometry: geometryAsText } }}
                                    className={`${classes.backBtn} g-button`}
                                >
                                    {secondaryBtnTitle}
                                </Link>
                                {preview && (
                                    <PrimaryButton
                                        disabled={false}
                                        onClick={() => {
                                            window.open(
                                                baseApiUrl + `passport/${passportId}/get_docx?preview=${preview}`
                                                // baseApiUrl + `passport/${22823}/get_docx?preview=${preview2}`
                                            );
                                        }}
                                        title="Печать"
                                        className={classes.printBtn}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <SimpleTabs tabsLabels={tabsLabels} tabsContent={tabsContent} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PassportView;
