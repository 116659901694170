import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
};

const NoRowsOverlay = ({ text }) =>
    <tr>
        <th>
            <div style={styles.text}>
                {text}
            </div>
        </th>
    </tr>;

NoRowsOverlay.defaultProps = {
    text: 'Нет записей',
};

NoRowsOverlay.propTypes = {
    text: PropTypes.string,
};

export default NoRowsOverlay;
