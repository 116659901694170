import React from 'react';
import PropTypes from 'prop-types';

import jswl from 'js-wrapper-lib';

import { ValidatorForm, TextValidator, ValidatorComponent } from 'react-material-ui-form-validator';

export const BackendValidationContext = React.createContext({
    errors: {},
    getErrorMessageCallback: (name) => '',
    validateCallback: (inputName, value) => true,
    providerExists: false,
});

function BackFrontValidationForm(props) {
    // console.log('BackFrontValidationForm')

    const { children, backendValidationErrors, onError, noValidate, ...rest } = props;

    const [state, setState] = React.useState({
        backendValidationErrors: [],
    });

    const defaultErrorHandler = React.useCallback((errors) => {
        if (jswl.isDefined(errors[0].props.id)) {
            document.getElementById(errors[0].props.id).scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, []);

    let onErrorHandler = React.useMemo(() => {
        return jswl.isDefined(onError) ? onError : defaultErrorHandler;
    }, [onError]);

    const Form = React.useMemo(() => React.createRef(), []);

    React.useEffect(() => {
        setState((state) => {
            // console.log('up state!');
            return {
                ...state,
                backendValidationErrors: jswl.isDefined(props.backendValidationErrors)
                    ? props.backendValidationErrors
                    : [],
            };
        });
    }, [props.backendValidationErrors, Form]);

    React.useEffect(() => {
        setState((state) => {
            if (!jswl.isEmpty(state.backendValidationErrors)) {
                console.log('state.backendValidationErrors ', state.backendValidationErrors);
                console.log('VALIDATE!!! ', state, Form);
                console.log('make form unvalid');
                // Form.current.isFormValid(false);
                Form.current.submit();
            }

            return { ...state };
        });
    }, [state.backendValidationErrors, Form]);

    const validateValue = React.useCallback(
        (inputName, value) => {
            let errors = state.backendValidationErrors;

            let isErrorOnThisInput = !jswl.isEmpty(errors) && !jswl.isEmpty(errors[inputName]);
            let result = !isErrorOnThisInput || (!jswl.isEmpty(errors) && value != errors[inputName]['value']);

            if (isErrorOnThisInput && value != errors[inputName]['value']) {
                setState((state) => {
                    return { ...state, backendValidationErrors: [] };
                });
            }

            return result;
        },
        [state.backendValidationErrors, setState]
    );

    const getErrorMessage = React.useCallback(
        (name) => {
            let result = '';
            if (!jswl.isEmpty(props.backendValidationErrors) && !jswl.isEmpty(props.backendValidationErrors[name])) {
                result = props.backendValidationErrors[name]['message'];
            }

            return result;
        },
        [props.backendValidationErrors]
    );

    const providerValue = {
        errors: state.backendValidationErrors,
        getErrorMessageCallback: getErrorMessage,
        validateCallback: validateValue,
        providerExists: true,
    };

    return (
        <ValidatorForm
            ref={Form}
            onError={onErrorHandler}
            noValidate={jswl.isDefined(noValidate) ? noValidate : true}
            {...rest}
        >
            <BackendValidationContext.Provider value={providerValue}>{children}</BackendValidationContext.Provider>
        </ValidatorForm>
    );
}

BackFrontValidationForm.propTypes = {
    backendValidationErrors: PropTypes.object.isRequired,
    onError: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

export default BackFrontValidationForm;
