import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Button } from '@material-ui/core';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import { serviceTableColumns } from '@app-constants';
import ServiceView from '../ServiceView/ServiceView';
import NoRowsOverlay from '@app-universal/table/NoRowsOverlay';
import LoadingOverlay from '@app-universal/table/LoadingOverlay';
import Pagination from '@app-universal/table/Pagination';
import TableComponent from './TableComponent';

const ServiceListTable = (props) => {
    const { listState, paginationState, setPaginationState } = props;
    const [state, setState] = useState({ activeItem: null });

    const rowClickHandler = (params, e, toggleSidebar) => {
        setState((pr) => ({ ...pr, activeItem: params }));
        toggleSidebar();
    };

    return (
        <>
            <div className="table-holder" style={{ width: '100%' }}>
                <SidebarActivator
                    render={(toggleSidebar) => (
                        <TableComponent
                            services={listState.items}
                            toggleSidebar={toggleSidebar}
                            rowClickHandler={rowClickHandler}
                            loading={!listState.downloadingComplete}
                        />
                    )}
                    renderContent={(toggleSidebar) => (
                        <ServiceView item={state.activeItem} toggleSidebar={toggleSidebar} />
                    )}
                />
            </div>
            {listState.items.length ? (
                <Pagination paginationState={paginationState} setPaginationState={setPaginationState} />
            ) : null}
        </>
    );
};

export default ServiceListTable;
