import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from '@app-redusers';
import { deleteUser } from '@app-actions';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import Pagination from '@app-universal/table/Pagination';
import UserIndex from '../UserIndex';
import UserCell from './UserCell';
import UserListLoader from './UserListLoader';

const UserListGrid = (props) => {
    const { listState, reloadList, paginationState, setPaginationState } = props;
    const users = listState.items;
    const [state, setState] = useState({ activeItem: { id: null }, shouldShowForm: false });

    const currentUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();

    const cellClickHandler = (user, toggleSidebar, shouldShowForm) => {
        setState((pr) => ({ ...pr, activeItem: user, shouldShowForm }));
        toggleSidebar();
    };

    const removeUser = (id) => dispatch(deleteUser(id, () => reloadList()));

    return (
        <>
            <div className="users-wrapper">
                <div className="users-holder">
                    {!listState.downloadingComplete && <UserListLoader />}
                    <SidebarActivator
                        render={(toggleSidebar) =>
                            users.map((it, index) => (
                                <UserCell
                                    key={index}
                                    number={index + 1}
                                    userData={it}
                                    showForm={() => cellClickHandler(it, toggleSidebar, true)}
                                    showCard={() => cellClickHandler(it, toggleSidebar, false)}
                                    isEditable={it.id !== currentUser.id}
                                    removeUser={removeUser}
                                />
                            ))
                        }
                        renderContent={(toggleSidebar) => (
                            <UserIndex
                                userId={state.activeItem.id}
                                showForm={state.shouldShowForm}
                                toggleSidebar={toggleSidebar}
                                reloadList={reloadList}
                                isEditable={state.activeItem.id !== currentUser.id}
                            />
                        )}
                    />
                </div>
            </div>
            {listState.items.length ? (
                <Pagination paginationState={paginationState} setPaginationState={setPaginationState} />
            ) : null}
        </>
    );
};

UserListGrid.propTypes = {
    listState: PropTypes.object.isRequired,
    reloadList: PropTypes.func,
    setPaginationState: PropTypes.func,
    paginationState: PropTypes.object,
};

export default UserListGrid;
