import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BackFrontValidationForm from '@app-universal/form/BackendValidationSupport/BackFrontValidationForm';
import { SelectValidationInput } from '@app-universal/form/inputs/SelectValidationInput';
import { TextValidationInput } from '@app-universal/form/inputs/TextValidationInput';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import { getSurfaceList, getZoneList, getTerritoryClossList, getTerritoryGroupList, getTerritoryDistrictList, getAllPasports } from '@app-actions';
import { CircularProgress, InputLabel } from '@material-ui/core';
import { AutoCompleteInput } from '@app-universal/form/inputs/AutoCompleteInput';

const styles = {
    loaderFilterBlock: {
        textAlign: 'center',
        marginTop: '10%',
    },
};

export default function ObjectEditForm({
    geom,
    group,
    objClass,
    district,
    ID_6993,
    len_6993,
    address,
    type_zone,
    type_surve,
    area,
    len_curb,
    len_nocurb,
    onCancel,
    onSubmit,
    onError,
    handleChangeCheckbox,
    handleChangeInt,
    handleChangeFloat,
    handleChangeText,
    disableForm,
    backendValidationErrors,
    setFormState,
    formState,
}) {

    const [groups, setGroups] = useState([]);
    const [classes, setClasses] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [zones, setZones] = useState([]);
    const [surfaces, setSurfaces] = useState([]);
    const [passports, setPassports] = useState([]);

    const dispatch = useDispatch();

    const getCurrentValue = (label, data) => {
        return data?.find(item => item.label === label)?.value;
    }

    const getCurrentValueInt = (label, data) => {
        return data?.find(item => item.value === label)?.value;
    }

    const getCurrentValueZonesOrSurfacec = (label, data) => {
        return data?.find(item => item.value === label)?.value;
    }

    const generateSelectDefaultValue = () => ({
        value: 0,
        label: <span className='select-placeholder'>Выберите значение</span>
    })

    const handleSuccessGroupListLoad = ({ items }) => {
        let updatedGroups = [generateSelectDefaultValue(), ...groups];
        items.forEach((it) => updatedGroups.push({ value: it.id, label: it.name }));
        setGroups(updatedGroups)
    };

    const handleSuccessClassesListLoad = ({ items }) => {
        let updatedClasses = [generateSelectDefaultValue(), ...classes];
        items.forEach((it) => updatedClasses.push({ value: it.id, label: it.name }));
        setClasses(updatedClasses)
    };

    const handleSuccessDistrictsListLoad = ({ items }) => {
        let updatedDistricts = [generateSelectDefaultValue(), ...districts];
        items.forEach((it) => updatedDistricts.push({ value: it.id, label: it.name }));
        setDistricts(updatedDistricts)
    };

    const handleSuccessZonesListLoad = ({ items }) => {
        let updatedZones = [generateSelectDefaultValue(), ...zones];
        items.forEach((it) => updatedZones.push({ value: it.id, label: it.name }));
        setZones(updatedZones)
    };

    const handleSuccessSurfacesListLoad = ({ items }) => {
        let updatedSurfaces = [generateSelectDefaultValue(), ...surfaces];
        items.forEach((it) => updatedSurfaces.push({ value: it.id, label: it.name }));
        setSurfaces(updatedSurfaces)
    };

    const handleSuccessPassportListLoad = ({ items }) => {
        let updatedPassports = [...passports];
        items.forEach((it, index) => updatedPassports.push({ id: index, label: it.road_id }));
        setPassports(updatedPassports)
    };

    const handleChangeSelect = (e, data) => {
        const value = e.target.value;
        const name = e.target.name;
        const valueForBackend = data.find(item => item.value === value).label;
        setFormState({ ...formState, [name]: valueForBackend });
    }

    const handleChangeSelectInt = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setFormState({ ...formState, [name]: value });
    }

    const handleAutoComplete = (event, newValue) => {
        setFormState({ ...formState, ['ID_6993']: newValue?.label || null });
    }

    const getCurrentValueAutoComplete = (label, data) => {
        return data.find(item => item.label === label)
    }
    

    const isDataLoading = groups.length && classes.length && districts.length && zones.length && surfaces.length && passports.length;

    useEffect(() => {
        dispatch(getTerritoryGroupList(handleSuccessGroupListLoad));
        dispatch(getTerritoryClossList(handleSuccessClassesListLoad));
        dispatch(getTerritoryDistrictList(handleSuccessDistrictsListLoad));
        dispatch(getZoneList(handleSuccessZonesListLoad));
        dispatch(getSurfaceList(handleSuccessSurfacesListLoad));
        dispatch(getAllPasports(handleSuccessPassportListLoad));
    }, [])

    return (
        <BackFrontValidationForm
            backendValidationErrors={backendValidationErrors}
            onSubmit={onSubmit}
            onError={onError}
        >
            <div class="tabs-content-item faded">
                <div class="b-form">
                    {isDataLoading ? (
                    <div class="form-wrapper">
                        <div className="form-row one-column">
                            <div className="form-element">
                                <InputLabel for="address" class="form-label">
                                    Идентификатор паспорта
                                </InputLabel>
                                <AutoCompleteInput
                                    id="ID_6993"
                                    name="ID_6993"
                                    options={passports}
                                    disabled={disableForm}
                                    onChange={handleAutoComplete}
                                    value={getCurrentValueAutoComplete(ID_6993, passports) || null}
                                // optionsOpenMinLettersQuantity={3}
                                />
                        </div>
                        </div>
                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={getCurrentValueInt(group, groups) || 0}
                                onChange={(e) => handleChangeSelectInt(e)}
                                name="group"
                                label="Группа"
                                options={groups}
                                disabled={disableForm}
                                required={false}
                            />
                        </div>

                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={getCurrentValueInt(objClass, classes) || 0}
                                onChange={(e) => handleChangeSelectInt(e)}
                                name="class"
                                label="Класс территории"
                                options={classes}
                                disabled={disableForm}
                                required={false}
                            />
                        </div>
                            <div className="form-row one-column">
                                <div className="form-element">
                                    <InputLabel for="address" class="form-label">
                                        Адрес
                                    </InputLabel>
                                    <TextValidationInput
                                        variant="outlined"
                                        id="address"
                                        name="address"
                                        label="Адрес"
                                        placeholder="Адрес"
                                        value={address}
                                        onChange={handleChangeText}
                                        withRequiredValidator
                                        disabled={disableForm}
                                        />
                                </div>
                            </div>

                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={getCurrentValue(district, districts) || 0}
                                onChange={(e) => handleChangeSelect(e, districts)}
                                name="district"
                                label="Внутригородской округ"
                                options={districts}
                                disabled={disableForm}
                                required={false}
                            />
                        </div>

                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={getCurrentValueInt(type_zone, zones) || 0}
                                onChange={(e) => handleChangeSelectInt(e)}
                                name="type_zone"
                                label="Вид территории"
                                options={zones}
                                disabled={disableForm}
                                required={false}
                            />
                        </div>

                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={getCurrentValueInt(type_surve, surfaces) || 0}
                                onChange={(e) => handleChangeSelectInt(e)}
                                name="type_surve"
                                label="Вид покрытия"
                                options={surfaces}
                                disabled={disableForm}
                                required={false}
                            />
                        </div>

                            <div className="form-row one-column">
                                <div className="form-element">
                                    <InputLabel for="area" class="form-label">
                                        Площадь, м2
                                    </InputLabel>
                                    <TextValidationInput
                                        variant="outlined"
                                        id="area"
                                        name="area"
                                        placeholder="Введите площадь"
                                        value={area}
                                        onChange={handleChangeFloat}
                                        withRequiredValidator
                                        disabled={disableForm}
                                    />
                                </div>
                            </div>

                            <div className="form-row one-column">
                                <div className="form-element">
                                    <InputLabel for="len_curb" class="form-label">
                                        Протяженность вдоль бордюра, v
                                    </InputLabel>
                                    <TextValidationInput
                                        variant="outlined"
                                        id="len_curb"
                                        name="len_curb"
                                        placeholder="Длина вдоль бордюра"
                                        value={len_curb}
                                        onChange={handleChangeFloat}
                                        withRequiredValidator
                                        disabled={disableForm}
                                    />
                                </div>
                            </div>

                            <div className="form-row one-column">
                                <div className="form-element">
                                    <InputLabel for="len_nocurb" class="form-label">
                                        Протяженность без бордюра, м
                                    </InputLabel>
                                    <TextValidationInput
                                        variant="outlined"
                                        id="len_nocurb"
                                        name="len_nocurb"
                                        placeholder="Длина без бордюра"
                                        value={len_nocurb}
                                        onChange={handleChangeFloat}
                                        withRequiredValidator
                                        disabled={disableForm}
                                    />
                            </div>
                        </div>
                    </div>
                    ) : (<div style={styles.loaderFilterBlock}>
                            <CircularProgress />
                        </div>)
                    }
                    <div class="controls-wrapper">
                        <div class="form-row controls-holder">
                            <SecondaryButton
                                type="button"
                                title="Отменить"
                                onClick={onCancel}
                                variant="outlined"
                            />
                            <PrimaryButton disabled={disableForm} type="submit" title="Сохранить" />
                        </div>
                    </div>

                </div>
            </div>
        </BackFrontValidationForm>
    );
}
