import React from 'react';
import red from '@material-ui/core/colors/red';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { SelectInput } from './SelectInput';

const red300 = red['500'];

const style = {
    fontSize: '12px',
    color: red300,
    position: 'absolute',
};

class SelectValidationInput extends ValidatorComponent {
    renderValidatorComponent() {
        const { errorMessages, validatorListener, ...rest } = this.props;
        return (
            <div>
                <SelectInput {...rest} />
                {this.errorText()}
            </div>
        );
    }
    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return <div style={style}>{this.getErrorMessage()}</div>;
    }
}
export { SelectValidationInput };
