import { createMuiTheme } from '@material-ui/core/styles';

export let theme = createMuiTheme({
    typography: {
        fontFamily: ['Roboto', 'sans-serif'],
        fontSize: 20,
    },
    body1: {
        backgroundColor: '#eaeef1',
        fontSize: 'inherit',
    },
    palette: {
        primary: {
            light: '#63ccff',
            dark: '#1F2A45',
            main: '#2E3E67',
        },
        secondary: {
            main: '#FFF',
        },
        text: {
            primary: '#000000',
            secondary: '#2E3E67',
        },
    },
    text: {
        // The most important text.
        // primary: '#424448',
        // Secondary text.
        // secondary: 'red',
        // Disabled text have even lower visual prominence.
        disabled: 'rgba(0, 0, 0, 0.38)',
    },
    shape: {
        borderRadius: 4,
    },
});

theme = {
    ...theme,
    overrides: {
        MuiFormControl: {
            marginNormal: {
                marginTop: 8,
                marginBottom: 0,
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '0.7em',
                lineHeight: 1.43,

                '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline ': {
                    border: '1px solid #2E3E67',
                },
            },
            input: {
                height: '',
            },
            inputMultiline: {
                minHeight: 46,
                boxSizing: 'border-box',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '2px',
            },
            input: {
                padding: '9px 16px',
                backgroundColor: '#ffffff',
                color: 'rgba(0, 0, 0, 0.87)',

                '&::placeholder': {
                    color: '#000',
                    opacity: 0.3,
                },
            },
            notchedOutline: {
                borderColor: '#D8D8D8',
            },
            inputMultiline: {
                padding: '9px 16px',
            },
        },
        MuiAutocomplete: {
            root: {
                borderRadius: '2px',
                '&:not(.Mui-focused) label': {
                    paddingLeft: '16px',
                    marginBottom: 0,
                    top: '5px',
                },
            },
            inputRoot: {
                paddingTop: '3px',
                paddingBottom: '3px',
                paddingLeft: '16px',
                flexGrow: 1,
                textOverflow: 'ellipsis',
                border: '1px solid #2E3E67',
                borderRadius: '2px',
                '&::before, &::after': {
                    display: 'none',
                    borderBottom: 'none',
                },
                '&:not(.Mui-focused)': {
                    border: '1px solid #00000033',
                },
                '& input': {
                    color: '#000',
                }
            },
            endAdornment: {
                top: 'calc(50% - 19px)',
            },
        },

        MuiInputLabel: {
            root: {
                fontSize: '.6em',
                lineHeight: '1.17',
                color: 'rgba(0,0,0,.38)',
            },
            outlined: {
                fontSize: '0.875rem',
                lineHeight: '1.3',
                color: 'rgba(0,0,0,.38)',
                transform: 'none',
            },
            shrink: {
                position: 'relative',
                transform: 'none !important',
                marginBottom: '8px',
            },

            focused: {
                color: 'rgba(0,0,0,.38) !important',
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,

                '&$edgeEnd': {
                    marginRight: -9,
                },
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0',
                minWidth: 0,
                padding: '0 16px 13px',
                fontSize: '0.7em',
                lineHeight: 1.142,
                maxWidth: '33.3%',
                minHeight: '0',
                opacity: 1,
                [theme.breakpoints.between(0, 1365)]: {
                    fontSize: '0.6em',
                    padding: '0 8px 13px',
                    width: '100%',
                    minWidth: 0,
                },
                borderBottom: '1px solid rgba(0, 0, 0, 0.54);',
            },
            textColorInherit: {
                color: '#000',
                opacity: 0.54,
            },
            selected: {
                color: '#2E3E67',
            },
        },
        MuiButton: {
            root: {
                fontSize: '0.7em',
                lineHeight: 1.21,
            },
        },

        MuiTypography: {
            body1: {
                fontSize: 'inherit',
            },
        },

        MuiBreadcrumbs: {
            li: {
                '& > a': {
                    color: 'rgba(0, 0, 0, 0.2)',

                    '& .active': {
                        color: '#2E3E67',
                    },
                },
            },
        },
        MuiTabs: {
            root: {
                minHeight: '0',
            },
            indicator: {
                backgroundColor: '#2E3E67',
                height: '3px',
                borderRadius: '1.5px',
            },
        },

        MuiBox: {
            root: {
                padding: 0,
            },
        },

        MuiSelect: {
            icon: {
                position: 'absolute',
                right: '16px !important',
                height: '100%',
                top: 0,
                width: 24,
                backgroundColor: 'transparent !important',
            },

            disabled: {
                backgroundColor: 'rgba(0,0,0,.04)',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: 'none',
                cursor: 'pointer',
            },
            stickyHeader: {
                backgroundColor: '#eaeef1',
                top: '4px',
            },
            head: {
                fontSize: '0.7em',
                lineHeight: 1.57,
                fontWeight: 400,
                color: 'rgba(0, 0, 0, 0.54)',
                padding: '17.5px 15px 17.5px 3px',
                [theme.breakpoints.between(1250, 1500)]: {
                    fontSize: '0.6em',
                },
                [theme.breakpoints.between(320, 1250)]: {
                    fontSize: '0.5em',
                },
            },

            body: {
                fontSize: '0.7em',
                lineHeight: 1.57,
                fontWeight: 400,
                color: '#000000',
                padding: '16px 43px 16px 3px',
                [theme.breakpoints.between(320, 1500)]: {
                    padding: '10px 16px 10px 3px',
                },
                [theme.breakpoints.between(1250, 1500)]: {
                    fontSize: '0.6em',
                    lineHeight: 1.15,
                },
                [theme.breakpoints.between(320, 1250)]: {
                    fontSize: '0.5em',
                    lineHeight: 1.15,
                },
            },
        },

        MuiTableSortLabel: {
            root: {
                minWidth: 7,
                marginLeft: 8,
            },
        },

        MuiTable: {
            root: {
                borderCollapse: 'separate',
                borderSpacing: '0 4px',
            },
        },

        MuiTableHead: {
            root: {
             position: 'sticky',
             top: '-4px',
            }
        },

        MuiTableRow: {
            root: {
                cursor: 'pointer',
            },
            head: {
                backgroundColor: '#eaeef1',
            },
            hover: {
                backgroundColor: '#fff',
            },
        },

        MuiFormHelperText: {
            error: {
                fontSize: '.55em',
                lineHeight: '1.45',
                fontWeight: '400',
                color: '#ff5252 !important',
                marginTop: '4px',
                marginLeft: '0 !important',
                marginRight: '0 !important',
            }
        },

        MuiFormLabel: {
            asterisk: {
                color: '#f44336',
            },
        },

        MuiTablePagination: {
            selectRoot: {
                fontSize: '12px',
                boxShadow: '0 6px 7px rgb(0 0 0 / 10%)',
                borderRadius: '4px',
                position: 'absolute',
                bottom: '50%',
                transform: 'translateY(50%)',
                right: 'calc(50% - 370px)',
                padding: 0,
            },
            selectIcon: {
                position: 'absolute',
                right: '16px !important',
                top: 0,
                width: 24,
                backgroundColor: 'transparent !important',
            },
            input: {
                color: 'rgba(0,0,0,.87)',
                fontSize: '14px',
                padding: '0 5px 0 8px',
            },
            menuItem: {
                fontSize: '14px',
                lineHeight: 1.42,
                padding: '8px 16px',
                color: 'rgba(0,0,0,.87)',
            }
        }
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
};

export const drawerWidth = 270;

export const styles = {
    '@global': {
        body: {
            backgroundColor: '#eaeef1',
        },
    },
    mainContent: {
        background: '#eaeef1',
    },
};
