import React from 'react';
import PropTypes from 'prop-types';
import jswl from 'js-wrapper-lib';
import { setPageTitle } from '@app-helpers/pageTitle';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import usePaginationState from '@app-universal/table/usePaginationState';
import FromStateHelper from '@app-universal/form/FromStateHelper';
import MainProgress from '@app-universal/progress/MainProgress';
import CenteredNote from '@app-universal/notes/CenteredNote';
import queryString from 'query-string';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import CustomBreadcrumbs from '@app-universal/navigation/CustomBreadcrumbs';
import {useLocation} from "react-router-dom";

function EntityList(props) {
    const dispatch = useDispatch();

    let getParams = !jswl.isEmpty(props.location) ? queryString.parse(props.location.search) : {};

    const {
        itemsListComponentCallback,
        listFilterComponentCallback,
        itemCreateComponentCallback,
        pageTitle,
        loadItemsListCallback,
        defaultFilterState,
        fixedQueryParams,
        externalOptions,
        emptyFilterState,
        primaryBtnComponentCallback,
        routes,
        initialItemsPerPage,
        showFilter
    } = props;

    const defaultFilterStateObject = !jswl.isEmpty(defaultFilterState) ? { ...defaultFilterState, ...getParams } : {};

    const fixedQueryParamsObject = React.useMemo(() => {
        return !jswl.isEmpty(fixedQueryParams) ? fixedQueryParams : {};
    }, [fixedQueryParams]);

    React.useEffect(() => {
        if (!jswl.isEmpty(pageTitle)) {
            setPageTitle(pageTitle);
        }
    }, []);

    const listFSH = FromStateHelper(
        {
            items: [],
            fullResponse: null,
            downloadingComplete: false,
            downloadInProcess: false,
        },
        {}
    );

    const { formState: listState, setFormState: setListState, getFormState: getListState } = listFSH;

    const filterFSH = FromStateHelper(defaultFilterStateObject, {});

    const { formState: filterState, setFormState: setFilterState, getFormState: getFilterState } = filterFSH;

    const [paginationState, setPaginationState, updatePaginationStateFromResponse] =
        usePaginationState(initialItemsPerPage);
    const location = useLocation();
    const getListQueryParams = React.useCallback(() => {
        return {
            limit: paginationState.itemsPerPage,
            page: paginationState.currentPageNumber + 1,
            type: location.pathname.includes('/passport/list') ? 'road' : 'recreation',
            ...fixedQueryParamsObject,
            ...getFilterState(),
        };
    }, [getListState, paginationState.itemsPerPage, paginationState.currentPageNumber, fixedQueryParamsObject, location.pathname]);

    const clearFilter = React.useCallback(() => {
        filterFSH.setFormState(emptyFilterState);
    }, [filterFSH.setFormState]);

    const listFilterComponent = React.useMemo(() => {
        return listFilterComponentCallback(filterFSH, getListQueryParams, clearFilter, listState.items.length);
    }, [filterFSH.formState, getListQueryParams]);

    const handleListGettingRequest = React.useCallback(
        (requestData, params) => {
            if (!jswl.isDefined(params)) {
                throw new Error('Your should pass defined value of params array from callback!');
            }

            //  console.log('>>>>in callback state', getListQueryParams());

            if (JSON.stringify(getListQueryParams()) === JSON.stringify(params)) {
                // console.log('++++states are SAME!');
                setListState({
                    ...getListState(),
                    items: requestData.items,
                    fullResponse: requestData,
                    downloadingComplete: true,
                    downloadInProcess: false,
                });

                updatePaginationStateFromResponse(requestData);
            } else {
                // если ответ пришел на более старый запрос
                // console.log('++++states are DEFFERENT!', getListQueryParams(), params);
                setListState({
                    ...getListState(),
                    downloadingComplete: true,
                    downloadInProcess: false,
                });
                loadList();
            }
        },
        [getListState, setListState, updatePaginationStateFromResponse, getListQueryParams]
    );

    const loadList = React.useCallback(() => {
        console.log('loadList!');
        setListState({
            ...getListState(),
            downloadInProcess: true,
            downloadingComplete: false,
        });

        dispatch(loadItemsListCallback(handleListGettingRequest, getListQueryParams()));
    }, [getListState, handleListGettingRequest, getListQueryParams]);

    /**
     * @var Object набор дополнительных вывозов/сущностей, которые могут пригодиться к колбеке списка,
     * например обратный вызов для перезагрузки списка
     */
    const Tools = React.useMemo(() => {
        return {
            reloadListCallback: loadList,
        };
    }, [loadList]);

    const itemsListComponent = React.useMemo(() => {
        return itemsListComponentCallback(listState, paginationState, setPaginationState, Tools, externalOptions);
    }, [listState.items, listState.downloadingComplete, paginationState, setPaginationState, Tools, externalOptions]);

    const primaryBtnComponent = React.useMemo(() => {
        return primaryBtnComponentCallback({ filterState, listState, Tools });
    }, [filterState, listState, Tools]);

    React.useEffect(
        // последующие загрузки
        () => {
            // console.log('load list params', [...Object.values(getListQueryParams())]);

            if (!getListState().downloadInProcess) {
                loadList();
            } else {
                console.log('stop request!');
                console.count('stop request!');
            }
        },
        [getListState, ...Object.values(getListQueryParams()),location.pathname] // paginationState.currentPageNumber
    );

    return (
        <>
            <div class="subheader">
                <CustomBreadcrumbs routes={routes} />
                <div class="controls-holder">
                    {/* <SidebarActivator
                        render={(toggleSidebar) => <PrimaryButton title={primaryBtnLabel} onClick={toggleSidebar} />}
                        renderContent={(toggleSidebar) =>
                            itemCreateComponentCallback({
                                toggleSidebar,
                                filterFSH,
                                getListQueryParams,
                                clearFilter,
                                listState,
                                Tools,
                            })
                        }
                    /> */}
                    {primaryBtnComponent}
                    {showFilter && (
                        <SidebarActivator
                            render={(toggleSidebar) => (
                                <SecondaryButton title="Фильтр" onClick={toggleSidebar} showIcon />
                            )}
                            renderContent={(toggleSidebar) =>
                                listFilterComponentCallback({
                                    toggleSidebar,
                                    filterFSH,
                                    getListQueryParams,
                                    clearFilter,
                                    listState,
                                    setFilterState,
                                    emptyFilterState,
                                })
                            }
                        />
                    )}
                </div>
            </div>
            {itemsListComponent}
        </>
    );
}

EntityList.defaultProps = {
    itemCreateComponentCallback: () => {},
    primaryBtnComponentCallback: () => {},
    initialItemsPerPage: 5,
    showFilter: true,
};

EntityList.propTypes = {
    itemsListComponentCallback: PropTypes.func.isRequired,
    primaryBtnComponentCallback: PropTypes.func,
    listFilterComponentCallback: PropTypes.func.isRequired,
    itemCreateComponentCallback: PropTypes.func,
    pageTitle: PropTypes.string,
    loadItemsListCallback: PropTypes.func.isRequired,
    defaultFilterState: PropTypes.object,
    fixedQueryParams: PropTypes.object,
    externalOptions: PropTypes.object,
    emptyFilterState: PropTypes.object,
    location: PropTypes.object,
    routes: PropTypes.array,
    initialItemsPerPage: PropTypes.number,
    showFilter: PropTypes.bool,
};

const NoChachedEntityList = EntityList;

export default React.memo(EntityList);
export { NoChachedEntityList };
