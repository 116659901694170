export const createStrFromTasksArr = (tasks) => {
    if (tasks.length) {
        return tasks.map((it) => it.name).join(', ');
    }
    return 'Не указано';
};

export const recountExtent = (url, iwidth, iheight) => {
    const urlObj = new URL(url);

    const extent = urlObj.searchParams.get('extent').split(',');
    const [xmin, ymin, xmax, ymax] = [+extent[0], +extent[1], +extent[2], +extent[3]];

    const k = iwidth / iheight;
    const xcenter = (xmax + xmin) / 2;
    const ycenter = (ymax + ymin) / 2;
    const obj_width = xmax - xmin;
    const obj_height = ymax - ymin;

    const obj_width_new = obj_height * k;
    const obj_width_div_2 = obj_width_new / 2;

    const xmin_new = xcenter - obj_width_div_2;
    const xmax_new = xcenter + obj_width_div_2;

    const newExtent = [xmin_new, ymin, xmax_new, ymax].join(',');

    urlObj.searchParams.set('extent', newExtent);
    return urlObj.href;
};
