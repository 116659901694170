import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = () => ({
    formLink: {
        marginLeft: 0,

        '& .MuiCheckbox-root': {
            padding: 0,
            marginRight: 11,
        },

        '& .MuiIconButton-label': {
            width: 18,
            height: 18,
            border: '2px solid rgba(0,0,0,.74)',
            borderRadius: 2,
            boxSizing: 'border-box',
        },

        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
        },

        '& .MuiFormControlLabel-label': {
            fontSize: '0.7em',
            lineHeight: 1.21,
        }
    },
});

function DefCheck(props) {
    return (
        <SvgIcon {...props} width="16" height="13" viewBox={'0 0 16 13'} fill="none">
            <path d="M1 4.91536L5.34483 11L15 1" stroke="none" fill="none" strokeWidth="2" />
        </SvgIcon>
    );
}

function ActiveCheck(props) {
    return (
        <SvgIcon {...props} width="14" height="11" viewBox={'0 0 14 11'} fill="none">
            <path d="M4.75012 8.1275L1.62262 5L0.557617 6.0575L4.75012 10.25L13.7501 1.25L12.6926 0.192505L4.75012 8.1275Z" fill="#2E3E67"/>
        </SvgIcon>
    );
}

function CheckboxInput(props) {
    const { classes } = props;

    const label = props.label ? props.label : '';

    const labelPlacement = props.labelPlacement ? props.labelPlacement : 'end';
    const isActive = props.isActive !== undefined ? props.isActive : true;

    return (
        <FormControlLabel
            className={classes.formLink}
            control={
                <Checkbox
                    color="primary"
                    icon={<DefCheck />}
                    checkedIcon={<ActiveCheck />}
                    disabled={!isActive || props.disabled}
                    checked={props.value ? props.value : false}
                    onChange={props.onChange}
                    name={props.name}
                    inputProps={props.inputProps}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
        />
    );
}

CheckboxInput.propTypes = {
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    labelPlacement: PropTypes.string, // см. labelPlacement для  https://material-ui.com/ru/api/form-control-label/
    tooltipProps: PropTypes.object,
    isActive: PropTypes.bool,
};

CheckboxInput = React.memo(withStyles(styles)(CheckboxInput));
export { CheckboxInput };
