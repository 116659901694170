import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hide } from '../../actions/notifications';
import IconCloseAlert from '@app-images/icon_alert_close.svg';

class Notification extends React.Component {
    componentDidMount() {
        setTimeout(this.hide, this.props.delay);
    }

    hide = () => {
        this.props.hide(this.props.notification.id);
    };

    render() {
        return (
            <div class="b-alerts">
                <div class="alerts-holder">{this.renderAlert(this.props.notification.message)}</div>
            </div>
        );
    }

    renderAlert(message) {
        const { kind } = this.props.notification;
        switch (kind) {
            case 'success':
                return (
                    <div class="alert done">
                        <div class="alert-title">Выполнено</div>
                        <div class="alert-message">{message}</div>
                        <div onClick={this.hide} class="alert-close">
                            <img src={IconCloseAlert} alt="close" />
                        </div>
                    </div>
                );

            case 'attention':
                return (
                    <div class="alert attention">
                        <div class="alert-title">Ошибка</div>
                        <div class="alert-message">{message}11</div>
                        <div onClick={this.hide} class="alert-close">
                            <img src={IconCloseAlert} alt="close" />
                        </div>
                    </div>
                );

            case 'failure':
                return (
                    <div class="alert error">
                        <div class="alert-title">Ошибка</div>
                        <div class="alert-message">{message}</div>
                        <div onClick={this.hide} class="alert-close">
                            <img src={IconCloseAlert} alt="close" />
                        </div>
                    </div>
                );

            default:
                throw new Error(`No notification icon for ${kind}`);
        }
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            hide,
        },
        dispatch
    );

export default connect(undefined, mapDispatchToProps)(Notification);
