import { notifications } from '../constants/notifications';

export const reset = () => (dispatch) => dispatch({ type: RESET });

export const show = (dispatch, { message, kind }) => {
    const id = new Date().getTime().toString();

    dispatch({
        type: notifications.SHOW,
        id,
        kind,
        message,
    });
};

export const hide = (id) => (dispatch) => dispatch({ type: notifications.HIDE, id });

export const showSuccessNotification = (message) => (dispatch) => {
    const id = new Date().getTime().toString();

    return dispatch({
        type: notifications.SHOW,
        id,
        kind: 'success',
        message,
    });
};

export const showAttentionNotification = (message) => (dispatch) => {
    const id = new Date().getTime().toString();

    return dispatch({
        type: notifications.SHOW,
        id,
        kind: 'attention',
        message,
    });
};

export const showFailureNotification = (message) => (dispatch) => {
    const id = new Date().getTime().toString();

    console.log('show fali!');
    return dispatch({
        type: notifications.SHOW,
        id,
        kind: 'failure',
        message,
    });
};

export const allSelector = (state) => {
    const { notifications } = state;
    return notifications.ids.map((id) => {
        return {
            ...notifications.all[id],
            id,
        };
    });
};
