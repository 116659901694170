import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

function ColoredLinearProgress(props) {
    const { classes } = props;
    return (
        <LinearProgress
            {...props}
            classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
            }}
        />
    );
}

const styles = (props) => ({
    colorPrimary: {
        backgroundColor: '#727997', // from color
    },
    barColorPrimary: {
        backgroundColor: '#2E3E67', // to color
    },
});

export default withStyles(styles)(ColoredLinearProgress);
