import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';

export default function SidebarActivator(props) {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const toggleSidebar = () => setIsOpen(!isOpen);

    const onCloseCallback = () => {
        props.beforeCloseCallback();
        toggleSidebar();
    };

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <>
            {props.render(toggleSidebar)}
            <Drawer anchor={'right'} open={isOpen} onClose={onCloseCallback}>
                {props.renderContent && props.renderContent(toggleSidebar)}
            </Drawer>
        </>
    );
}

SidebarActivator.defaultProps = {
    isOpen: false,
    beforeCloseCallback: () => {},
};

SidebarActivator.propTypes = {
    isOpen: PropTypes.bool,
    beforeCloseCallback: PropTypes.func,
};
