import React from 'react';
import { getSurfaceList } from '@app-actions';
import SurfaceListTable from './SurfaceListTable';
import EntityList from '@app-universal/list/EntityList';
import SurfaceFilter from '../SurfaceFilter/SurfaceFilter';

const routes = [
    {
        label: 'Справочники',
        link: 'handbooks',
        disabled: true,
    },
    {
        label: 'Виды поверхностей',
        link: 'handbooks/surface/list',
    },
];

function SurfaceList(props) {
    const itemsListComponentCallback = React.useCallback((listState, paginationState, setPaginationState, Tools) => {
        return (
            <SurfaceListTable
                listState={listState}
                paginationState={paginationState}
                setPaginationState={setPaginationState}
                Tools={Tools}
            />
        );
    }, []);

    const listFilterComponentCallback = React.useCallback((props) => {
        const { filterFSH, setFilterState, clearFilter, listState, toggleSidebar } = props;
        return (
            <>
                <SurfaceFilter
                    formState={filterFSH?.formState}
                    clearFilter={clearFilter}
                    toggleSidebar={toggleSidebar}
                    listState={listState}
                    setFilterState={setFilterState}
                    emptyFilterState={emptyFilterState}
                />
            </>
        );
    }, []);

    const defaultFilterState = React.useMemo(() => {
        return {
            name: '',
        };
    }, []);

    const emptyFilterState = React.useMemo(() => {
        return {
            name: '',
        };
    }, []);

    return (
        <div className="b-reference-book">
            <div className="g-container">
                <EntityList
                    itemsListComponentCallback={itemsListComponentCallback}
                    listFilterComponentCallback={listFilterComponentCallback}
                    pageTitle="Виды поверхностей"
                    loadItemsListCallback={getSurfaceList}
                    defaultFilterState={defaultFilterState}
                    routes={routes}
                    emptyFilterState={emptyFilterState}
                    initialItemsPerPage={50}
                />
            </div>
        </div>
    );
}

export default SurfaceList;
