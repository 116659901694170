import { ActionTypes } from '../constants';
import jswl from 'js-wrapper-lib';
import notificationReducer from './notificationReducer';

const initialState = {
    isError: false,
    userName: '',   // отображаемое имя
    isEmailConfirmed: true,
    currentUserId: null,
    userRoles: [],
    account: {},
    previousLoginsData: [], // массив объектов, содержищих сведения о предыдущих сессиях (нужен для сквозной авторизации)
    isUserAuthed: false, // авторизован или нет
    isAppInited: false, // иницилизирвоано ли приложение
    photoPath: null,
    notifications: undefined,
    currentUser: undefined,
    currentUserBalance: null,
    showMainProgress: false,
    contractState: undefined,
    invoiceState: undefined,
    publicSiteState: {},
    currentLocation: '/', // текущий URL (может устанавилваться любым компонентом с подключенным роутером, у нас это App.js)
    snackbarsCallback: () => { }, // колбек для броска сообщений из любого кода, которому доступен глобальный state
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ERROR:
            console.log('erorr!!');
            return {
                ...state,
                isError: true,
            }
        case ActionTypes.AUTHED:
            // console.log('user is authed!!');
            return {
                ...state,
                userName: action.userName,
                currentUser: action.currentUser,
                userRoles: action.userRoles,
                account: action.account,
                isUserAuthed: true,
                currentUserId: action.currentUserId,
                photoPath: action.photoPath,
                isEmailConfirmed: action.isEmailConfirmed,
                previousLoginsData: !jswl.isEmpty(action.previousLoginsData) ?
                    action.previousLoginsData : [],
            }
        case ActionTypes.LOGOUT:
            console.log('user is logout!!');
            return {
                ...state,
                userName: '',
                isUserAuthed: false
            }
        case ActionTypes.APP_INITED:
            return {
                ...state,
                isAppInited: true
            }
        case ActionTypes.SET_SHACKBAR_CALLBACK:
            return {
                ...state,
                snackbarsCallback: action.snackbarsCallback
            }
        case ActionTypes.SHOW_MAIN_PROGRESS:
            return {
                ...state,
                showMainProgress: true
            }
        case ActionTypes.HIDE_MAIN_PROGRESS:
            return {
                ...state,
                showMainProgress: false
            }
        case ActionTypes.SAVE_PREVIOUS_AUTH_DATA:
            //console.log('action.loginData', action.loginData);
            let loginsData = state.previousLoginsData;
            loginsData.push(action.loginData);
            localStorage.setItem('previousLoginsData', JSON.stringify(loginsData));
            return {
                ...state,
                previousLoginsData: loginsData,
            }
        case ActionTypes.POP_LAST_PREVIOUS_AUTH_DATA:
            let loginItemsData = state.previousLoginsData;

            console.log('loginItemsData 1', loginItemsData);
            loginItemsData.pop();
            console.log('loginItemsData 2', loginItemsData);
            localStorage.setItem('previousLoginsData', JSON.stringify(loginItemsData));
            return {
                ...state,
                previousLoginsData: loginItemsData,
            }
        case ActionTypes.SET_CURRENT_LOCATION:
            // console.log('new location', action.location);
            return {
                ...state,
                previousLocation: state.currentLocation,
                currentLocation: action.location,
            }
        case ActionTypes.SET_USER_BALANCE:
            console.log('ActionTypes.SET_USER_BALANCE action', action);
            return {
                ...state,
                currentUserBalance: action.value,
            }
        default:
            return state;
    }
}

export default (state, action) => {
    const newState = rootReducer(state, action);
    return {
        ...newState,
        notifications: notificationReducer(newState.notifications, action),
    };
};