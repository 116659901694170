import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, Button } from '@material-ui/core';

const TableRowComponent = ({ data, onClick }) => {
    const { id, name, code } = data;
    return (
        <TableRow hover className="table-row body-table">
            <TableCell component="td" scope="row" className="cell double-width">
                {id ?? ''}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {name ?? 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                <div className="td-item-wrapper">{code}</div>
            </TableCell>
        </TableRow>
    );
};

TableRowComponent.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

export default TableRowComponent;
