import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import FromStateHelper from '@app-universal/form/FromStateHelper';
import { createUser } from '@app-actions';
import { useDispatch } from 'react-redux';
import UserCreateForm from './UserCreateForm';

const UserCreate = ({ toggleSidebar, reloadList }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({ backendValidationErrors: {}, lockSubmit: false });
    const userStateHelper = FromStateHelper(
        {
            email: '',
            firstName: '',
            plainPassword: '',
            roles: ['ROLE_SPECIALIST'],
            phone: '',
            secondName: '',
            surname: '',
            isActive: true,
        },
        {}
    );
    const { formState, setFormState, getFormState } = userStateHelper;
    const handleReset = () => toggleSidebar();
    const handleSubmit = (event) => {
        event.preventDefault();
        setState((pr) => ({ ...pr, lockSubmit: true }));

        dispatch(
            createUser(
                formState,
                () => {
                    toggleSidebar();
                    reloadList();
                },
                handleFail
            )
        );
    };
    const handleFail = (error) => {
        console.log('error.responseJSON.data', error);
        setState((pr) => ({
            ...pr,
            lockSubmit: false,
            backendValidationErrors: error && error.responseJSON ? error.responseJSON.data : 'error',
        }));
    };

    const handleError = () => {};
    return (
        <div className="users-holder">
            Создание пользователя
            <UserCreateForm
                onSubmit={handleSubmit}
                onReset={handleReset}
                onError={handleError}
                email={formState.email}
                firstName={formState.firstName}
                plainPassword={formState.plainPassword}
                phone={formState.phone}
                secondName={formState.secondName}
                surname={formState.surname}
                roles={formState.roles}
                isActive={formState.isActive}
                handleChangeSelect={userStateHelper.handleChange}
                handleChangeText={userStateHelper.handleChangeText}
                handleChangeCheckbox={userStateHelper.handleChangeCheckbox}
                backendValidationErrors={state.backendValidationErrors}
                setFormState={setFormState}
                formState={formState}
            />
        </div>
    );
};

UserCreate.propTypes = {
    toggleSidebar: PropTypes.func.isRequired,
    reloadList: PropTypes.func.isRequired,
};

export default UserCreate;
