import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getNeedShowMainProgressBar } from '@app-redusers';
import ReactPaginate from 'react-paginate';
import IconPrev from '@app-images/icon_paginate_prev.svg'
import IconNext from '@app-images/icon_paginate_next.svg'

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        // marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, pageCount, rowsPerPage, onChangePage, isLoadingInProgress } = props;

    return (
        <div className={classes.root} title={isLoadingInProgress ? 'Выполняется загрузка данных...' : ''}>
            <ReactPaginate
                previousLabel={<img src={IconPrev} />}
                nextLabel={<img src={IconNext} />}
                breakLabel="..."
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={isLoadingInProgress ? null : onChangePage}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
};

export default connect((state) => ({
    isLoadingInProgress: getNeedShowMainProgressBar(state),
}))(TablePaginationActions);
