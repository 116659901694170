import React from 'react';
import { initApp } from '../../../actions/actions.js';
import { connect } from 'react-redux';
import AppLoaderView from './AppLoaderView';

class AppInitControl extends React.Component {
    componentDidMount() {
        this.props.dispatch(initApp());
    }

    render() {
        return <AppLoaderView />;
    }
}

//export default AuthControl;

export default connect((state) => ({}))(AppInitControl);
