export const formatFloat = (val) => {
    return new Intl.NumberFormat('ru', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
        val
    );
};

export const formatLength = (val) => {
    return formatFloat(val) + ' м';
};

export const formatSquare = (val) => {
    return formatFloat(val) + ' м2';
};