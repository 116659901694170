import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { getFullName, getRoleName } from '@app-pages/User/userHelpers';
import RoutePath from '@app-universal/routing/RoutePath';
import UserIcon from '@app-images/icon_user.svg';
import DropSmallIcon from '@app-images/icon_drop_small.svg';
import { withStyles } from '@material-ui/core/styles';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import UserEditSelf from '@app-pages/User/UserEdit/UserEditSelf';

const StyledMenu = withStyles((theme) => ({
    paper: {
        paddingTop: 28,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible !important',
    },
}))(Menu);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: '0.8em',
        lineHeight: '1.5',
        padding: '12px 19px',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

function HeaderMenu({ currentUser, logoutCallback }) {
    const { firstName, surname, type } = currentUser;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);
    const handleLogout = () => {
        handleClose();
        logoutCallback();
    };

    return (
        <div>
            <div className="account-info-holder">
                <div className="hold-info" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <div className="user-name">
                        <div>{getFullName(firstName, surname)}</div>
                        <img className="user-drop-icon" src={DropSmallIcon} alt="" />
                    </div>
                    <div className="user-pos">{getRoleName(type)}</div>
                </div>
                <div className="hold-icon">
                    <img className="user-icon" src={UserIcon} alt="" />
                </div>
            </div>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop: 28, padding: 0 }}
            >
                <div className="dropdown-wrapper">
                    <SidebarActivator
                        render={(toggleSidebar) => <StyledMenuItem onClick={toggleSidebar}>Профиль</StyledMenuItem>}
                        renderContent={(toggleSidebar) => <UserEditSelf toggleSidebar={toggleSidebar} />}
                    />
                    <StyledMenuItem onClick={handleLogout}>Выход</StyledMenuItem>
                </div>
            </StyledMenu>
        </div>
    );
}

export default HeaderMenu;
