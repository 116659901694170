import sendRequest from '@app-helpers/ApiRequestHelper/ApiClient';

export const getZoneList =
    (successCallback, params = null) =>
    async (dispatch) => {
        await sendRequest(dispatch, {
            method: 'get',
            url: 'terr_type_zone',
            data: params,
            successCallback: successCallback,
        });
    };
