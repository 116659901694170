// let UserRoles = {
//     superAdmin: 'ROLE_ADMIN',
//     controller: 'ROLE_CONTROLLER',
//     specialist: 'ROLE_SPECIALIST',
// };

let UserRoles = {
    specialist: { label: 'Специалист', value: 1, valueStr: 'ROLE_SPECIALIST', valueForBackend: ['ROLE_SPECIALIST'] },
    controller: { label: 'Контроллер', value: 2, valueStr: 'ROLE_CONTROLLER', valueForBackend: ['ROLE_CONTROLLER'] },
    superAdmin: { label: 'Администратор', value: 3, valueStr: 'ROLE_ADMIN', valueForBackend: ['ROLE_ADMIN'] },
};

UserRoles = Object.freeze(UserRoles);

export { UserRoles };
