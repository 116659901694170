import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleTabs from '@app-universal/navigation/SimpleTabs';
import ObjectEditForm from './includes/ObjectEditForm';
import FromStateHelper from '@app-universal/form/FromStateHelper';
import { updateObject} from '@app-actions';

export default function ObjectEdit({ objectData, toggleMode }) {

    const dispatch = useDispatch();
    const {
        id,
        geom,
        group,
        objClass,
        district,
        ID_6993,
        len_6993,
        address,
        type_zone,
        type_surve,
        area,
        len_curb,
        len_nocurb,
    } = objectData;

    const [state, setState] = useState({ backendValidationErrors: {}, lockSubmit: false });
    
    const objectStateHelper = FromStateHelper(
        {
            geom: objectData.geom,
            group: objectData.group?.id || 0,
            class: objectData.class?.id || 0 ,
            district: objectData.district,
            ID_6993: objectData.ID_6993,
            len_6993: objectData.len_6993,
            address: objectData.address,
            type_zone: objectData.type_zone?.id || 0,
            type_surve: objectData.type_surve?.id || 0,
            area: objectData.area,
            len_curb: objectData.len_curb,
            len_nocurb: objectData.len_nocurb,
        },
        {}
    );
    const { formState, setFormState, getFormState } = objectStateHelper;

    const defaultAddress = 'Адрес не указан';


    const handleReset = () => toggleMode();
    const handleSubmit = (event) => {
        event.preventDefault();
        setState((pr) => ({ ...pr, lockSubmit: true }));
        
        dispatch(
            updateObject(
                id,
                formState,
                () => {
                    toggleMode();
                    console.log('success callback console.log!!')
                },
                () => setState((pr) => ({ ...pr, lockSubmit: false }))
            )
        );
    };
    const handleFail = () => {};

    return (
        <div class="edit-modals-holder">
            <div class="b-modal-edit">
                <div class="modal-content-wrapper">
                    <div class="modal-header ">
                        <div class="title">{address || defaultAddress}</div>
                    </div>
                    <div class="modal-body">
                        <ObjectEditForm
                            geom={formState.geom}
                            group={formState.group}
                            objClass={formState.class}
                            district={formState.district}
                            ID_6993={formState.ID_6993}
                            len_6993={formState.len_6993}
                            address={formState.address}
                            type_zone={formState.type_zone}
                            type_surve={formState.type_surve}
                            area={formState.area}
                            len_curb={formState.len_curb}
                            len_nocurb={formState.len_nocurb}
                            onSubmit={handleSubmit}
                            onError={handleFail}
                            onCancel={handleReset}
                            // handleChangeSelect={objectStateHelper.handleChangeInt}
                            handleChangeInt={objectStateHelper.handleChangeInt}
                            handleChangeFloat={objectStateHelper.handleChangeFloat}
                            handleChangeText={objectStateHelper.handleChangeText}
                            handleChangeCheckbox={objectStateHelper.handleChangeCheckbox}
                            disableForm={state.lockSubmit}
                            backendValidationErrors={state.backendValidationErrors}
                            setFormState={setFormState}
                            formState={formState}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
