import React from 'react';
import { CircularProgress } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        textAlign: 'center',
    },
};

const UserListLoader = () => {
    return (
        <div style={styles.root}>
            <CircularProgress />
        </div>
    );
};

export default UserListLoader;
