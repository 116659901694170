import React from 'react';
import BackFrontValidationForm from '@app-universal/form/BackendValidationSupport/BackFrontValidationForm';
import { SelectValidationInput } from '@app-universal/form/inputs/SelectValidationInput';
import { TextValidationInput } from '@app-universal/form/inputs/TextValidationInput';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import { districtList } from '../testData/districtList';
import { id6993List } from '../testData/id6993List';
import { surfaceTypeList } from '../testData/surfaceTypes';
import { territoryTypeList } from '../testData/territoryTypeList';

export default function InfoForm({
    formState,
    handleChangeSelect,
    handleChangeText,
    backendValidationErrors,
    onSubmit,
    onError,
    toggleSidebar,
    disableForm
}) {

    return (
        <BackFrontValidationForm
            backendValidationErrors={backendValidationErrors}
            onSubmit={onSubmit}
            onError={onError}
        >
            <div class="tabs-content-item faded">
                <div class="b-form">
                    <div class="form-wrapper">
                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.group}
                                onChange={handleChangeSelect}
                                name="group"
                                label="Код группы объектов санитарного содержания"
                                options={[
                                    { value: 0, label: 'Выберите значение' },
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                ]}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>
                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.class}
                                onChange={handleChangeSelect}
                                name="class"
                                label="Класс"
                                options={[
                                    { value: 0, label: 'Выберите значение' },
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                ]}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>
                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.district}
                                onChange={handleChangeSelect}
                                name="district"
                                label="Внутригородской округ"
                                options={districtList}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>

                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.ID_6993}
                                onChange={handleChangeSelect}
                                name="ID_6993"
                                label="Идентификатор"
                                options={id6993List}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>

                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.len_6993}
                                onChange={handleChangeSelect}
                                name="len_6993"
                                label="Длина улицы, м"
                                options={[
                                    { value: 0, label: 'Выберите значение' },
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                ]}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>

                        <label for="addressFrom" class="form-label">
                            Адрес
                        </label>
                        <div class="form-row two-column">
                            <div class="form-element">
                                <TextValidationInput
                                    variant="outlined"
                                    id="addressFrom"
                                    name="addressFrom"
                                    label="Введите значение, от"
                                    placeholder="sdff"
                                    value={formState.addressFrom}
                                    onChange={handleChangeText}
                                    withRequiredValidator
                                    disabled={disableForm}
                                    validators={['required']}
                                    errorMessages={['Поле необходимо заполнить']}
                                />
                            </div>
                            <div class="form-element">
                                <TextValidationInput
                                    variant="outlined"
                                    id="addressTo"
                                    name="addressTo"
                                    label="Введите значение, до"
                                    value={formState.addressTo}
                                    onChange={handleChangeText}
                                    withRequiredValidator
                                    disabled={disableForm}
                                    validators={['required']}
                                    errorMessages={['Поле необходимо заполнить']}
                                />
                            </div>
                        </div>
                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.type_zone}
                                onChange={handleChangeSelect}
                                name="type_zone"
                                label="Вид территории"
                                options={territoryTypeList}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>
                        <div className="form-row one-column">
                            <SelectValidationInput
                                value={formState.type_surve}
                                onChange={handleChangeSelect}
                                name="type_surve"
                                label="Вид покрытия"
                                options={surfaceTypeList}
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Это обязательное поле']}
                            />
                        </div>
                        <div className="form-row one-column">
                            <TextValidationInput
                                variant="outlined"
                                id="area"
                                name="area"
                                label="Площадь, м2"
                                value={formState.area}
                                onChange={handleChangeText}
                                withRequiredValidator
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Поле необходимо заполнить']}
                            />
                        </div>
                        <div className="form-row one-column">
                            <TextValidationInput
                                variant="outlined"
                                id="len_curb"
                                name="len_curb"
                                label="Протяженность вдоль бордюра"
                                value={formState.len_curb}
                                onChange={handleChangeText}
                                withRequiredValidator
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Поле необходимо заполнить']}
                            />
                        </div>
                        <div className="form-row one-column">
                            <TextValidationInput
                                variant="outlined"
                                id="len_nocurb"
                                name="len_nocurb"
                                label="Протяженность без бордюров"
                                value={formState.len_nocurb}
                                onChange={handleChangeText}
                                withRequiredValidator
                                disabled={disableForm}
                                validators={['required']}
                                errorMessages={['Поле необходимо заполнить']}
                            />
                        </div>
                    </div>
                    <div class="controls-wrapper">
                        <div class="form-row controls-holder">
                            <SecondaryButton title="Отменить" onClick={toggleSidebar} variant="outlined" />
                            <PrimaryButton disabled={disableForm} type="submit" title="Сохранить" />
                        </div>
                    </div>
                </div>
            </div>
        </BackFrontValidationForm>
    );
}
