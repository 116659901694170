let zoneTableColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
        field: 'name',
        headerName: 'Наименование территории',
        flex: 4,
        editable: true,
        // valueGetter: (value) => (value.value ? value.value.name : 'Не указано'),
    },
    {
        field: 'code',
        headerName: 'Код',
        flex: 8,
        editable: true,
    },
];

zoneTableColumns = Object.freeze(zoneTableColumns);

export { zoneTableColumns };
