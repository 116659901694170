import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { zoneTableColumns } from '@app-constants';
import NoRowsOverlay from '@app-universal/table/NoRowsOverlay';
import LoadingOverlay from '@app-universal/table/LoadingOverlay';
import Pagination from '@app-universal/table/Pagination';
import TableComponent from './TableComponent';

const ZoneListTable = (props) => {
    const { listState, paginationState, setPaginationState } = props;

    return (
        <>
            <div className="table-holder" style={{ width: '100%' }}>
                <TableComponent
                    zones={listState.items}
                    loading={!listState.downloadingComplete}
                />
            </div>
            {listState.items.length ? (
                <Pagination paginationState={paginationState} setPaginationState={setPaginationState} />
            ) : null}
        </>
    );
};

export default ZoneListTable;
