import React from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import jswl from 'js-wrapper-lib';
import { Tooltip } from '@material-ui/core';

function TextValidationInput(props) {
    const placeholder = props.placeholder ? props.placeholder : 'noname';
    const id = props.id ? props.id : props.name;
    const validators = !jswl.isEmpty(props.validators) ? props.validators : [];
    const errorMessages = !jswl.isEmpty(props.errorMessages) ? props.errorMessages : [];
    const helperText = props.helperText ? props.helperText : '';
    const type = props.type ? props.type : 'text';
    const disabled = props.disabled ? props.disabled : false;

    let value = props.value ? props.value : '';

    let onChange = props.onChange;
    let onBlur = props.onBlur ? props.onBlur : function (event) {};
    let onFocus = props.onFocus ? props.onFocus : function (event) {};
    let onKeyUp = props.onKeyUp ? props.onKeyUp : function (event) {};
    let onKeyDown = props.onKeyDown ? props.onKeyDown : function (event) {};

    const componentContent = () => {
        return (
            <div>
                <TextValidator
                    value={value}
                    variant="outlined"
                    //margin="normal"
                    fullWidth
                    name={props.name}
                    placeholder={placeholder}
                    type={type}
                    id={id}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    validators={validators}
                    errorMessages={errorMessages}
                    helperText={helperText}
                    inputProps={props.inputProps}
                    InputProps={props.InputProps}
                    disabled={disabled}
                />
            </div>
        );
    };

    if (props.tooltipProps) {
        return <Tooltip {...props.tooltipProps}>{componentContent()}</Tooltip>;
    }
    return componentContent();
}

TextValidationInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    tooltipProps: PropTypes.object,
};

TextValidationInput = React.memo(TextValidationInput);
export { TextValidationInput };
