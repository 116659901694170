import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Button } from '@material-ui/core';
import NoRowsOverlay from '@app-universal/table/NoRowsOverlay';
import LoadingOverlay from '@app-universal/table/LoadingOverlay';
import TableRowComponent from './TableRowComponent';
import useSorting from '@app-universal/hooks/useSorting';

const TableComponent = ({ objects, rowClickHandler, toggleSidebar, loading }) => {
    const hasObjects = !!objects.length;
    const [sortingState, setSortingState, sort, getIcon] = useSorting(objects);
    return (
        <Table stickyHeader={true}>
            <TableHead>
                <TableRow className="table-row head-table">
                    <TableCell component="th" className="cell double-width" onClick={() => sort('group.name')}>
                        <div className="th-item-wrapper">
                            Группа
                            <img className="sorting-icon" src={getIcon('group.name')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('class.name')}>
                        <div className="th-item-wrapper">
                            Класс
                            <img className="sorting-icon" src={getIcon('class.name')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width">
                        <div className="th-item-wrapper" onClick={() => sort('district')}>
                            Округ
                            <img className="sorting-icon" src={getIcon('district')} />
                        </div>
                        <div className="th-item-wrapper" onClick={() => sort('address')}>
                            Улица
                            <img className="sorting-icon" src={getIcon('address')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('ID_6993')}>
                        <div className="th-item-wrapper">
                            ID_6993
                            <img className="sorting-icon" src={getIcon('ID_6993')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('len_6993')}>
                        <div className="th-item-wrapper">
                            Длина улицы
                            <img className="sorting-icon" src={getIcon('len_6993')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('type_zone.name')}>
                        <div className="th-item-wrapper">
                            Вид территории
                            <img className="sorting-icon" src={getIcon('type_zone.name')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('type_surve.name')}>
                        <div className="th-item-wrapper">
                            Вид покрытия
                            <img className="sorting-icon" src={getIcon('type_surve.name')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" >
                        <div className="th-item-wrapper"  onClick={() => sort('tasks')}>
                            Вид услуги
                            <img className="sorting-icon" src={getIcon('tasks')} />
                        </div>
                        <div className="th-item-wrapper"  onClick={() => sort('area')}>
                            Площадь
                            <img className="sorting-icon" src={getIcon('area')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('len_curb')}>
                        <div className="th-item-wrapper">
                            Протяж. с бордюрами
                            <img className="sorting-icon" src={getIcon('len_curb')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('len_nocurb')}>
                        <div className="th-item-wrapper">
                            Протяж. без бордюров
                            <img className="sorting-icon" src={getIcon('len_nocurb')} />
                        </div>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading ? (
                    <LoadingOverlay />
                ) : hasObjects ? (
                    sortingState.data.map((it) => (
                        <TableRowComponent
                            key={it.id}
                            data={it}
                            onClick={(e) => rowClickHandler(it, e, toggleSidebar)}
                        />
                    ))
                ) : (
                    <NoRowsOverlay />
                )}
            </TableBody>
        </Table>
    );
};

TableComponent.defaultProps = {
    objects: [],
    loading: false,
};

TableComponent.propTypes = {
    objects: PropTypes.array,
    rowClickHandler: PropTypes.func,
    toggleSidebar: PropTypes.func,
    loading: PropTypes.bool,
};

export default TableComponent;
