import React from 'react';
import { useSelector } from 'react-redux';
import jswl from 'js-wrapper-lib';
import { DataGrid } from '@material-ui/data-grid';
import queryString from 'query-string';
import { getCurrentUser } from '@app-redusers';
import SidebarActivator from '@app-universal/sidebar/SidebarActivator';
import PrimaryButton from '@app-universal/buttons/PrimaryButton';
import SecondaryButton from '@app-universal/buttons/SecondaryButton';
import { getPassportList } from '@app-actions';
import EntityList from '@app-universal/list/EntityList';
import PassportListTable from './PassportListTable';
import { baseApiUrl } from '@app-helpers/clientConfig';
import { UserRoles } from '@app/constants/userRoles';
import PassportFilter from './PassportFilter/PassportFilter';
import {useLocation} from "react-router-dom";



function PassportList(props) {

    const location = useLocation();
    const isUDS = location.pathname.includes('passport/list');
    const routes = isUDS ? [
        {
            label: 'УДС',
            link: 'passport/list',
        }
    ] : [{
        label: 'Скверы',
        link: 'recreation/list',
    }]
    const currentUser = useSelector(getCurrentUser);
    const itemsListComponentCallback = React.useCallback((listState, paginationState, setPaginationState, Tools) => {
        return (
            <PassportListTable
                listState={listState}
                paginationState={paginationState}
                setPaginationState={setPaginationState}
                Tools={Tools}
            />
        );
    }, []);

    const listFilterComponentCallback = React.useCallback((props) => {
        const { filterFSH, clearFilter, listState, toggleSidebar, setFilterState } = props;
        return (
            <PassportFilter
                formState={filterFSH?.formState}
                clearFilter={clearFilter}
                toggleSidebar={toggleSidebar}
                listState={listState}
                setFilterState={setFilterState}
                emptyFilterState={emptyFilterState}
            />
        );
    }, []);

    const primaryBtnComponentCallback = React.useCallback(({ filterState, listState }) => {
        const disabled = !listState.downloadingComplete || !listState.items.length;
        const hasAccess = jswl.arr.isAnyInArray(currentUser.roles, [
            UserRoles.superAdmin.valueStr,
            UserRoles.specialist.valueStr,
        ]);
        return (
            hasAccess && ( //TODO Подумать, как вынести это в EntityList
                <PrimaryButton
                    disabled={disabled}
                    onClick={() => {
                        window.open(baseApiUrl + 'passport/get_xlsx?'+ (location.pathname.includes('passport/list') ? 'type=road&' :'type=recreation&') + queryString.stringify(filterState));
                    }}
                    title="Выгрузить в xlsx"
                />
            )
        );
    }, [location.pathname]);

    const defaultFilterState = React.useMemo(() => {
        return {
            query: '',
            road_id: '',
            adm_okr: '',
        };
    }, []);

    const emptyFilterState = React.useMemo(() => {
        return {
            query: '',
            road_id: '',
            adm_okr: '',
        };
    }, []);

    return (
        <>
            <section className="b-reference-book territory-list">
                <div class="g-container">
                    <EntityList
                        itemsListComponentCallback={itemsListComponentCallback}
                        listFilterComponentCallback={listFilterComponentCallback}
                        // itemCreateComponentCallback={itemCreateComponentCallback}
                        pageTitle="Паспорта"
                        loadItemsListCallback={getPassportList}
                        defaultFilterState={defaultFilterState}
                        primaryBtnLabel="Выгрузить в xlsx"
                        emptyFilterState={emptyFilterState}
                        primaryBtnComponentCallback={primaryBtnComponentCallback}
                        routes={routes}
                        initialItemsPerPage={50}
                    />
                </div>
            </section>
        </>
    );
}

export default PassportList;
