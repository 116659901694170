import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSurfaceList, getZoneList, getTerritoryClossList, getTerritoryGroupList } from '@app-actions';
import { SelectInput } from '@app-universal/form/inputs/SelectInput';
import { CircularProgress, TextField } from '@material-ui/core';

const styles = {
    loaderFilterBlock: {
        textAlign: 'center',
        marginTop: '10%',
    },
};

export default function ServiceFilter({
    formState,
    clearFilter,
    toggleSidebar,
    listState,
    setFilterState,
    emptyFilterState,
}) {
    const dispatch = useDispatch();
    const [localFormState, setLocalFormState] = useState(formState);

    const onTextInputChange = (e) => {
        const { name, value } = e.target;
        setLocalFormState((pr) => ({ ...pr, [name]: value }));
    };
    const onSelectInputChange = (e) => {
        const { name, value } = e.target;
        setLocalFormState((pr) => ({ ...pr, [name]: parseInt(value) }));
    };

    const [state, setState] = useState({
        isSurfaceDataLoaded: false,
        isZoneDataLoaded: false,
        data: {
            surfaces: [{ value: 0, label: <span className='select-placeholder'>Выберите значение</span> }],
            zones: [{ value: 0, label: <span className='select-placeholder'>Выберите значение</span> }],
        },
    });

    const handleResetFilter = () => {
        clearFilter();
        setLocalFormState(emptyFilterState);
        // toggleSidebar();
    };

    const handleSubmit = () => {
        setFilterState(localFormState);
    };

    const handleSuccessSurfaceListLoad = ({ items }) => {
        let surfaces = [...state.data.surfaces];
        items.forEach((it) => surfaces.push({ value: it.id, label: it.name }));
        setState((pr) => ({ ...pr, isSurfaceDataLoaded: true, data: { ...pr.data, surfaces } }));
    };
    const handleSuccessZoneListLoad = ({ items }) => {
        let zones = [...state.data.zones];
        items.forEach((it) => zones.push({ value: it.id, label: it.name }));
        setState((pr) => ({ ...pr, isZoneDataLoaded: true, data: { ...pr.data, zones } }));
    };

    useEffect(() => {
        dispatch(getSurfaceList(handleSuccessSurfaceListLoad));
        dispatch(getZoneList(handleSuccessZoneListLoad));
    }, []);

    const isFilterDataLoaded = state.isZoneDataLoaded && state.isZoneDataLoaded;

    const disableForm = false;
    const totalCount = listState.fullResponse ? listState.fullResponse.paginationData.totalCount : 0;
    return (
        <div class="b-modal-filter">
            <div class="modal-content-wrapper">
                <div class="modal-header ">
                    <div class="title">Фильтр</div>
                    <div class="subtitle">Найдено: {totalCount}</div>
                </div>
                <div class="modal-body without-tabs">
                    <div class="b-form">
                        {isFilterDataLoaded ? (
                            <div class="form-wrapper">
                                <div class="row-title with-top-border">Наименование</div>
                                <TextField
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    placeholder="Введите значение"
                                    value={localFormState.name}
                                    onChange={onTextInputChange}
                                    disabled={disableForm}
                                    fullWidth
                                />
                                <div class="row-title with-top-border">Где выполняется</div>
                                <SelectInput
                                    value={localFormState.type_zone_id}
                                    onChange={onSelectInputChange}
                                    name="type_zone_id"
                                    options={state.data.zones}
                                />
                                <div class="row-title with-top-border">Тип покрытия</div>
                                <SelectInput
                                    value={localFormState.type_surve_id}
                                    onChange={onSelectInputChange}
                                    name="type_surve_id"
                                    options={state.data.surfaces}
                                />
                            </div>
                        ) : (
                            <div style={styles.loaderFilterBlock}>
                                <CircularProgress />
                            </div>
                        )}
                        <div class="controls-wrapper">
                            <div class="form-row controls-holder">
                                <button class="g-button white with-border" onClick={handleResetFilter}>
                                    Сброс
                                </button>
                                <button class="g-button" type="button" onClick={handleSubmit}>
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
