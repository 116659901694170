import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({}));

function NotFoundPage(props) {
    const classes = useStyles();

    return (
        <span>
            <b>Ошибка:</b> Страница не найдена!
        </span>
    );
}

export default connect((state) => ({}))(NotFoundPage);
