import React from 'react';
import { CircularProgress } from '@material-ui/core';

const styles = {
    loader: {
        position: 'absolute',
        zIndex: 10,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
};

const LoadingOverlay = () => (
    <tr>
        <th>
            <div style={styles.loader}>
                <CircularProgress />
            </div>
        </th>
    </tr>
);

export default LoadingOverlay;
