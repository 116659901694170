import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { formatFloat, formatSquare } from '@app-helpers/formatters';

const RosreestrCard = ({ numbpkk, categorypkk, typepkk_bydoc, adresspkk, squarepkk, costpkk, datepkk, statuspkk }) => (
    <div className="tabs-content-item faded">
        <div className="info-holder with-border">
            <div className="info-group inline">
                <div className="info-group-title">Кадастровый номер участка:</div>
                <div className="info-group-value">{numbpkk || 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Категория земель:</div>
                <div className="info-group-value">{categorypkk || 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Вид разрешенного использования:</div>
                <div className="info-group-value">{typepkk_bydoc || 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Адрес:</div>
                <div className="info-group-value">{adresspkk || 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Декларированная площадь, м2:</div>
                <div className="info-group-value">{squarepkk ? formatSquare(squarepkk) : 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Кадастровая стоимость, руб:</div>
                <div className="info-group-value">{costpkk ? formatFloat(costpkk) : 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Дата постановки на учет:</div>
                <div className="info-group-value">{datepkk || 'Не указано'}</div>
            </div>
            <div className="info-group inline">
                <div className="info-group-title">Статус земельного участка:</div>
                <div className="info-group-value">{statuspkk || 'Не указано'}</div>
            </div>
        </div>
    </div>
);

const styles = {
    div: {
        textAlign: 'left',
        padding: '20px',
    },
};

function DataFromPKKBlock({ rosreestr, isPkkDataLoaded }) {
    return (
        <div>
            {isPkkDataLoaded ? (
                rosreestr.length ? (
                    rosreestr.map((it, index) => <RosreestrCard {...it} key={index} />)
                ) : (
                    <div className="tabs-content-item">
                        <div className="info-holder">
                            <div className="info-title">Нет данных</div>
                        </div>
                    </div>
                )
            ) : (
                <div style={styles.div}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

DataFromPKKBlock.defaultProps = {
    rosreestr: [],
};

DataFromPKKBlock.propTypes = {
    rosreestr: PropTypes.array.isRequired,
    isPkkDataLoaded: PropTypes.bool,
};

export default DataFromPKKBlock;
