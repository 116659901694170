import React from 'react';
import PropTypes from 'prop-types';
import { getFullName, getRoleName } from '../userHelpers';
import IconEditSmall from '@app-images/icon_edit_small.svg';

const UserView = ({ userData, switchToEdit, isEditable }) => {
    const { email, firstName, secondName, surname, phone, type } = userData;

    return (
        <div class="info-modals-holder">
            <div className="b-modal-info">
                <div className="modal-content-wrapper">
                    <div className="modal-header with-small-icon">
                        <div className="header-left">
                            <div className="title">{getFullName(firstName, surname)}</div>
                        </div>
                        {isEditable && <div className="header-right">
                            <div onClick={switchToEdit} className="edit-link">
                                <img className="icon" src={IconEditSmall} alt="icon-edit-small" />
                            </div>
                        </div>}
                    </div>
                    <div className="modal-body without-tabs">
                        <div className="info-holder with-border">
                            <div className="info-title">Информация</div>
                            <div className="info-group">
                                <div className="info-group-title">Имя</div>
                                <div className="info-group-value">{firstName || 'Не указано'}</div>
                            </div>
                            <div className="info-group">
                                <div className="info-group-title">Фамилия</div>
                                <div className="info-group-value">{surname || 'Не указана'}</div>
                            </div>
                            <div className="info-group">
                                <div className="info-group-title">Отчество</div>
                                <div className="info-group-value">{secondName || 'Не указано'}</div>
                            </div>
                            <div className="info-group">
                                <div className="info-group-title">Телефон</div>
                                <div className="info-group-value">{phone || 'Не указан'}</div>
                            </div>
                            <div className="info-group">
                                <div className="info-group-title">E-mail</div>
                                <div className="info-group-value">{email || 'Не указан'}</div>
                            </div>
                        </div>
                        <div className="info-holder">
                            <div className="info-title">Привилегии</div>
                            <div className="info-group">
                                <div className="info-group-title">Роль пользователя</div>
                                <div className="info-group-value">{getRoleName(type)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserView.propTypes = {
    userData: PropTypes.object,
    isEditable: PropTypes.bool,
    switchToEdit: PropTypes.func,
};

export default UserView;
