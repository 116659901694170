import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Button } from '@material-ui/core';
import NoRowsOverlay from '@app-universal/table/NoRowsOverlay';
import LoadingOverlay from '@app-universal/table/LoadingOverlay';
import TableRowComponent from './TableRowComponent';
import useSorting from '@app-universal/hooks/useSorting';

const TableComponent = ({ zones, rowClickHandler, toggleSidebar, loading }) => {
    const hasZones = !!zones.length;
    const [sortingState, setSortingState, sort, getIcon] = useSorting(zones);

    return (
        <Table stickyHeader={true}>
            <TableHead>
                <TableRow className="table-row head-table">
                    <TableCell component="th" className="cell double-width" onClick={() => sort('id')}>
                        <div className="th-item-wrapper">
                            ID
                            <img className="sorting-icon" src={getIcon('id')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('name')}>
                        <div className="th-item-wrapper">
                            Наименование территории
                            <img className="sorting-icon" src={getIcon('name')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('code')}>
                        <div className="th-item-wrapper">
                            Код
                            <img className="sorting-icon" src={getIcon('code')} />
                        </div>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading ? (
                    <LoadingOverlay />
                ) : hasZones ? (
                    sortingState.data.map((it) => <TableRowComponent key={it.id} data={it} />)
                ) : (
                    <NoRowsOverlay />
                )}
            </TableBody>
        </Table>
    );
};

TableComponent.defaultProps = {
    zones: [],
    loading: false,
};

TableComponent.propTypes = {
    zones: PropTypes.array,
    rowClickHandler: PropTypes.func,
    toggleSidebar: PropTypes.func,
    loading: PropTypes.bool,
};

export default TableComponent;
