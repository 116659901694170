import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { formatSquare } from '@app-helpers/formatters';

const styles = {
    div: {
        maxWidth: '325px',
        textAlign: 'center',
    },
};

const ExplicationBlock = ({ explication, isLoading }) => {
    return (
        <div className="tabs-content-item faded">
            <div className="info-holder with-border">
                <div className="info-title">Экспликация</div>
                {isLoading ? (
                    <div style={styles.div}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {!explication.length && (
                            <div className="info-group">
                                <div className="info-group-title">Не найдено</div>
                            </div>
                        )}
                        {explication.map(({ type_surve, type_zone, area }, index) => (
                            <div key={index} className="info-group">
                                <div className="info-group-title">
                                    Тип покрытия: {type_surve ? type_surve : 'Не указано'}
                                </div>
                                <div className="info-group-value">
                                    Вид территории: {type_zone ? type_zone : 'Не указано'}
                                </div>
                                <div className="info-group-value">{area ? formatSquare(area) : 'Не указано'}</div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default ExplicationBlock;
