import React from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '@app-helpers/pageTitle';
import {
    getIsSuperAdmin,
} from '../../reducers/index.js';
import { Redirect } from 'react-router-dom';
import RoutePath from '@app-universal/routing/RoutePath';

/**
 * "Разводящая" страница - определяет главную страницу через переадресацию:
 * Редиректит на нужную страницу в зависимости от роли пользователя
 */
function IndexPage(props) {
    React.useEffect(() => {
        setPageTitle('Главная');
    }, []);

    var body = (
        <p>
            Добро пожаловать на главную страницу! <br /> Роль вашего пользователя не определена и поэтому мы не знаем,
            что вам показать ;)
        </p>
    );

    // if (props.isSuperAdmin) {
        body = <Redirect to={RoutePath('object/list')} />;
    // }

    return <>{body}</>;
}

export default connect((state) => ({
    isSuperAdmin: getIsSuperAdmin(state),
}))(IndexPage);
