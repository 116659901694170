import React from 'react';
import IconEditSmall from '@app-images/icon_edit_small.svg';

const getZones = (value) => (!!value ? value.map((it) => it.name.toLowerCase()).join(', ') : 'Не указано');

const ServiceView = ({ item }) => {
    const { name, terrTypeSurve, terrTypeZones } = item;

    return (
        <div className="b-modal-info">
            <div className="modal-content-wrapper">
                <div className="modal-header with-small-icon">
                    <div className="header-left">
                        <div className="title">{name}</div>
                    </div>
                    {/* <div className="header-right">
                        <img class="icon" src={IconEditSmall} alt="icon" />
                    </div> */}
                </div>
                <div className="modal-body without-tabs">
                    <div className="info-holder with-border">
                        <div className="info-title">Информация</div>
                        <div className="info-group">
                            <div className="info-group-title">Наименование услуги</div>
                            <div className="info-group-value">{name}</div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Где выполняется</div>
                            <div className="info-group-value">{getZones(terrTypeZones)}</div>
                        </div>
                        <div className="info-group">
                            <div className="info-group-title">Тип покрытия</div>
                            <div className="info-group-value">{terrTypeSurve ? terrTypeSurve.name : 'Не указано'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceView;
