import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import configureStore from './store/index';

import App from './components/App';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

require('../../scss/includes.scss');
require('../../css/theme.css');

ReactDOM.render(
    <Provider store={configureStore()}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
                <Route path="/" component={App} />
            </BrowserRouter>
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root')
);
