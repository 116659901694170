import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getObject, getPkkData, getWorkTypes } from '@app-actions';
import ObjectView from './ObjectView/ObjectView';
import ObjectCreate from './ObjectCreate/ObjectCreate';

export default function ObjectIndex({ shouldShowForm, toggleSidebar, objectId }) {
    const [state, setState] = useState({
        isItemDataLoaded: false,
        isWorksDataLoaded: false,
        data: {
            ID_6993: null,
            address: null,
            area: null,
            class: null,
            district: null,
            group: null,
            len_6993: null,
            len_curb: null,
            len_nocurb: null,
            type_serv: null,
            type_surve: null,
            type_zone: null,
            geometryAsText: null,
        },
        showForm: shouldShowForm,
    });
    const dispatch = useDispatch();

    const toggleMode = () => setState((prev) => ({ ...prev, showForm: true }));

    const handleSuccessEntityLoad = (response) => {
        setState((pr) => ({ ...pr, isItemDataLoaded: true, data: { ...pr.data, ...response } }));
        // if (response.geometryAsGeojson) {
        //     getPkkData(response.geometryAsGeojson, handleSuccessPkkDataLoad);
        // } else if (response.address) {
        //     getPkkData(response.address, handleSuccessPkkDataLoad);
        // }
    };

    useEffect(() => {
        if (!state.showForm) {
            dispatch(getObject(objectId, handleSuccessEntityLoad));
        }
    }, []);

    return state.showForm ? (
        <ObjectCreate />
    ) : (
        <ObjectView
            objectData={state.data}
            ID_6993={state.data.ID_6993}
            address={state.data.address}
            area={state.data.area}
            objectClass={state.data.class}
            district={state.data.district}
            group={state.data.group}
            id={objectId}
            len_6993={state.data.len_6993}
            len_curb={state.data.len_curb}
            len_nocurb={state.data.len_nocurb}
            type_serv={state.data.type_serv}
            type_surve={state.data.type_surve}
            type_zone={state.data.type_zone}
            geometryAsText={state.data.geometryAsText}
            geometryAsGeojson={state.data.geometryAsGeojson}
            toggleMode={toggleMode}
        />
    );
}

ObjectIndex.defaultProps = {
    shouldShowForm: false,
};

ObjectIndex.propTypes = {
    shouldShowForm: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func,
    objectId: PropTypes.number,
};
