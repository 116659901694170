import React from 'react';
import { connect } from 'react-redux';
import { getIsError, getIsUserAuthed } from '@app-redusers';
import { Redirect } from 'react-router-dom';
import RoutePath from '@app-universal/routing/RoutePath';

import LoginForm from './forms/LoginForm';
import ColoredLinearProgress from '@app-universal/progress/ColoredLinearProgress';
import { getNeedShowMainProgressBar } from '@app-redusers';

function Login(props) {
    return props.isUserAuthed ? (
        <Redirect to={RoutePath('index')} />
    ) : (
        <div className="b-login">
            <div className="g-container">
                <div className="login-wrapper">
                    <div class="title">Авторизация</div>
                    {props.showHeaderProgressBar ? (
                        <ColoredLinearProgress color="primary" />
                    ) : (
                        <div style={{ height: '4px' }} />
                    )}
                    <LoginForm />
                </div>
            </div>
        </div>
    );
}

export default connect((state) => ({
    isError: getIsError(state),
    isUserAuthed: getIsUserAuthed(state),
    showHeaderProgressBar: getNeedShowMainProgressBar(state),
}))(Login);
