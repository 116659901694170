import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Button } from '@material-ui/core';
import NoRowsOverlay from '@app-universal/table/NoRowsOverlay';
import LoadingOverlay from '@app-universal/table/LoadingOverlay';
import TableRowComponent from './TableRowComponent';
import useSorting from '@app-universal/hooks/useSorting';
import {useLocation} from "react-router-dom";

const TableComponent = ({ objects, rowClickHandler, toggleSidebar, loading }) => {
    const hasObjects = !!objects.length;
    const [sortingState, setSortingState, sort, getIcon] = useSorting(objects);
    const location = useLocation();
    const isUds = location.pathname.includes('passport/list');

    return (
        <Table stickyHeader={true}>
            <TableHead>
                <TableRow className="table-row head-table">
                    <TableCell component="th" className="cell double-width" onClick={() => sort('name')}>
                        <div className="th-item-wrapper">
                            Наименование
                            <img className="sorting-icon" src={getIcon('name')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('toponym')}>
                        <div className="th-item-wrapper">
                            Топоним
                            <img className="sorting-icon" src={getIcon('toponym')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('road_id')}>
                        <div className="th-item-wrapper">
                            Идентификатор
                            <img className="sorting-icon" src={getIcon('road_id')} />
                        </div>
                    </TableCell>
                    <TableCell component="th" className="cell double-width" onClick={() => sort('adm_okr')}>
                        <div className="th-item-wrapper">
                            Адм. Округ
                            <img className="sorting-icon" src={getIcon('adm_okr')} />
                        </div>
                    </TableCell>
                    {isUds && (
                        <>
                            <TableCell align="center" component="th" className="cell double-width" onClick={() => sort('category')}>
                                <div className="th-item-wrapper">
                                    Категория
                                    <img className="sorting-icon" src={getIcon('category')} />
                                </div>
                            </TableCell>

                            <TableCell align="center" component="th" className="cell double-width" onClick={() => sort('len')}>
                                <div className="th-item-wrapper">
                                    Протяженность
                                    <img className="sorting-icon" src={getIcon('len')} />
                                </div>
                            </TableCell>
                        </>
                    )}
                    <TableCell align="center" component="th" className="cell double-width" onClick={() => sort('area')}>
                        <div className="th-item-wrapper">
                            Площадь
                            <img className="sorting-icon" src={getIcon('area')} />
                        </div>
                    </TableCell>
                    {!isUds && (
                        <>
                            <TableCell component="th" className="cell double-width" onClick={() => sort('address')}>
                                <div className="th-item-wrapper">
                                    Адрес
                                    <img className="sorting-icon" src={getIcon('road_id')} />
                                </div>
                            </TableCell>
                        </>
                    )}
                    {isUds && (
                        <>
                            <TableCell component="th" className="cell double-width" onClick={() => sort('blen')}>
                                <div className="th-item-wrapper">
                                    Протяж. с бордюрами
                                    <img className="sorting-icon" src={getIcon('blen')} />
                                </div>
                            </TableCell>
                            <TableCell component="th" className="cell double-width" onClick={() => sort('hblen')}>
                                <div className="th-item-wrapper">
                                    Протяж. без бордюров
                                    <img className="sorting-icon" src={getIcon('hblen')} />
                                </div>
                            </TableCell>
                        </>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading ? (
                    <LoadingOverlay />
                ) : hasObjects ? (
                    sortingState.data.map((it) => (
                        <TableRowComponent
                            key={it.gid}
                            data={it}
                            onClick={(e) => rowClickHandler(it, e, toggleSidebar)}
                        />
                    ))
                ) : (
                    <NoRowsOverlay />
                )}
            </TableBody>
        </Table>
    );
};

TableComponent.defaultProps = {
    objects: [],
    loading: false,
};

TableComponent.propTypes = {
    objects: PropTypes.array,
    rowClickHandler: PropTypes.func,
    toggleSidebar: PropTypes.func,
    loading: PropTypes.bool,
};

export default TableComponent;
