import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

const styles = {
    loaderFilterBlock: {
        textAlign: 'center',
        marginTop: '10%',
    },
};

export default function PassportFilter({
    formState,
    clearFilter,
    toggleSidebar,
    listState,
    setFilterState,
    emptyFilterState,
}) {
    const [localFormState, setLocalFormState] = useState(formState);

    const onTextInputChange = (e) => {
        const { name, value } = e.target;
        setLocalFormState((pr) => ({ ...pr, [name]: value }));
    };

    const handleResetFilter = () => {
        clearFilter();
        setLocalFormState(emptyFilterState);
        // toggleSidebar();
    };

    const handleSubmit = () => {
        setFilterState(localFormState);
        // toggleSidebar();
    };

    const disableForm = false;
    const totalCount = listState.fullResponse ? listState.fullResponse.paginationData.totalCount : 0;
    return (
        <div class="b-modal-filter">
            <div class="modal-content-wrapper">
                <div class="modal-header ">
                    <div class="title">Фильтр</div>
                    <div class="subtitle">Найдено: {totalCount}</div>
                </div>
                <div class="modal-body without-tabs">
                    <div class="b-form">
                        <div class="form-wrapper">
                            <div class="row-title with-top-border">Поиск</div>
                            <TextField
                                variant="outlined"
                                id="query"
                                name="query"
                                placeholder="Введите наименование"
                                value={localFormState.query}
                                onChange={onTextInputChange}
                                disabled={disableForm}
                                fullWidth
                            />
                            <div class="row-title with-top-border">Идентификатор</div>
                            <TextField
                                variant="outlined"
                                id="district"
                                name="road_id"
                                placeholder="Введите значение"
                                value={localFormState.district}
                                onChange={onTextInputChange}
                                disabled={disableForm}
                                fullWidth
                            />
                            <div class="row-title with-top-border">Административный округ</div>
                            <TextField
                                variant="outlined"
                                id="adm_okr"
                                name="adm_okr"
                                placeholder="Введите значение"
                                value={localFormState.adm_okr}
                                onChange={onTextInputChange}
                                disabled={disableForm}
                                fullWidth
                            />
                        </div>
                        <div class="controls-wrapper">
                            <div class="form-row controls-holder">
                                <button class="g-button white with-border" onClick={handleResetFilter}>
                                    Сброс
                                </button>
                                <button class="g-button" type="button" onClick={handleSubmit}>
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
