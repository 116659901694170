import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import jswl from 'js-wrapper-lib';
import { makeStyles } from '@material-ui/core/styles';
import MainProgressWrapper from '@app-universal/progress/MainProgressWrapper';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 180,
        width: '100%',
    },
    paper: {
        borderRadius: '0 0 2px 2px',
        margin: 0,
        border: '1px solid #00000033',
        '& ul': {
            padding: 0,
        }
    },
    popper: {
        padding: 0,
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
    },
    option: {
        fontSize: '14px',
        lineHeight: 1.42,
        whiteSpace: 'normal',
        padding: '8px 16px',
        color: 'rgba(0,0,0,.87)',
        fontSize: '14px',
    }
}));

function AutoCompleteInput({ id,
    options,
    name,
    label = '',
    placeholder = 'Выберите значение',
    disabled = false,
    onChange,
    value,
}) {

    const classes = useStyles();

    const autoCompleteClasses = {
        inputRoot: classes.inputRoot,
        label: classes.label,
        paper: classes.paper,
        popper: classes.popper,
        option: classes.option
    }


    return (
        <FormControl required className={classes.formControl}>
            <MainProgressWrapper
                isInited={!jswl.isEmpty(options)}
                text="Получение списка опций...."
            >
                <Autocomplete
                    options={options}
                    name={name}
                    value={value}
                    id={id}
                    onChange={onChange}
                    disabled={disabled}
                    classes={autoCompleteClasses}
                    getOptionLabel={option => option.label || ''}
                    renderInput={(params) =>
                        <TextField {...params} placeholder={placeholder}/>}
                />
            </MainProgressWrapper>
        </FormControl>
    );
}

AutoCompleteInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

AutoCompleteInput = React.memo(AutoCompleteInput);
export { AutoCompleteInput };
