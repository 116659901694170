import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import jswl from 'js-wrapper-lib';
import { makeStyles } from '@material-ui/core/styles';
import MainProgressWrapper from '@app-universal/progress/MainProgressWrapper';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 180,
        width: '100%',
    },
    paper: {
        borderRadius: '0 0 2px 2px',
        maxHeight: 175,
        maxWidth: 346,
    },
    list: {
        padding: 0,
    },
    menuItem: {
        fontSize: '0.7em',
        lineHeight: 1.42,
        whiteSpace: 'normal',
        padding: '8px 16px',
        color: 'rgba(0,0,0,.87)',
    }
}));

function SelectInput(props) {
    const { className } = props;
    const {required = true} = props
    const classes = useStyles();
    const { id, options, dispalayWithoutWaitingOptions } = props;

    const [pseudoValue, setPseudoValue] = React.useState();

    React.useEffect(() => {
        console.log('----!!!---function SelectInput(props) ----');
        setPseudoValue({});
    }, [options]);

    const label = props.label ? props.label : '';

    const value = jswl.isDefined(props.value) ? props.value : '';

    // console.log(`------SelectInput-------${label} SelectValidation`, value);

    const shrinkLabel = jswl.isDefined(props.shrinkLabel) ? props.shrinkLabel : undefined;

    return (
        <FormControl required={required} variant="outlined" className={classes.formControl}>
            <MainProgressWrapper
                isInited={dispalayWithoutWaitingOptions || !jswl.isEmpty(options)}
                text="Получение списка опций...."
            >
                {label ? (
                    <InputLabel htmlFor={id} shrink={shrinkLabel}>{label}</InputLabel>
                ) : null}
                <Select
                    value={value}
                    onChange={props.onChange}
                    // label={label}
                    inputProps={{
                        'data-array-index': props['data-array-index'],
                        name: props.name,
                        id: id,
                    }}
                    disabled={props.disabled}
                    IconComponent={ExpandMoreOutlinedIcon}
                    MenuProps={{
                        classes: {list: classes.list, paper: classes.paper },
                        variant: 'menu',
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}
                >
                    {!jswl.isEmpty(props.defaultOption) ? (
                        <MenuItem className={classes.menuItem} value={props.defaultOption.value}>{props.defaultOption.label}</MenuItem >
                    ) : null}
                    {options.map((item, index) => (
                        <MenuItem className={classes.menuItem}  key={index} value={item.value}>
                            {item.label}
                        </MenuItem >
                    ))}
                </Select>
            </MainProgressWrapper>
        </FormControl>
    );
}

SelectInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    defaultOption: PropTypes.object,
    shrinkLabel: PropTypes.bool,
    tooltipProps: PropTypes.object,
    dispalayWithoutWaitingOptions: PropTypes.bool,
};

SelectInput = React.memo(SelectInput);
export { SelectInput };
