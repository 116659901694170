import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import jswl from 'js-wrapper-lib';
import { createStrFromTasksArr } from '@app-pages/Object/objectHelpers';
import { formatSquare, formatLength } from '@app-helpers/formatters';

const TableRowComponent = ({ data, onClick }) => {
    const {
        group,
        class: objectClass,
        district,
        address,
        ID_6993,
        len_6993,
        type_zone,
        type_surve,
        len_curb,
        len_nocurb,
        area,
        tasks,
    } = data;

    return (
        <TableRow hover className="table-row body-table" onClick={onClick}>
            <TableCell component="td" scope="row" className="cell double-width">
                {group ? group.name : 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {objectClass ? objectClass.name : 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                <div className="td-item-wrapper">
                    {jswl.isDefined(district)
                        ? jswl.isEmpty(district.trim())
                            ? 'Округ не указан'
                            : district
                        : 'Округ не указан'}
                </div>
                <div className="td-item-wrapper">
                    {jswl.isDefined(address)
                        ? jswl.isEmpty(address.trim())
                            ? 'Улица не указана'
                            : address
                        : 'Улица не указана'}
                </div>
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {jswl.isDefined(ID_6993) ? (jswl.isEmpty(ID_6993.trim()) ? 'Не указано' : ID_6993) : 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {len_6993 ? formatLength(len_6993) : 'Не указан'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {type_zone ? type_zone.name : 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {type_surve ? type_surve.name : 'Не указано'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                <div className="td-item-wrapper">{createStrFromTasksArr(tasks)}</div>
                <div className="td-item-wrapper">{area ? formatSquare(area) : 'Площадь не указана'}</div>
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {len_curb ? formatLength(len_curb) : 'Не указан'}
            </TableCell>
            <TableCell component="td" scope="row" className="cell double-width">
                {len_nocurb ? formatLength(len_nocurb) : 'Не указан'}
            </TableCell>
        </TableRow>
    );
};

TableRowComponent.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

export default TableRowComponent;
