import sendRequest from '@app-helpers/ApiRequestHelper/ApiClient';
import queryString from 'query-string';
import { baseMapUrl } from '@app-helpers/clientConfig';

export const getObjectList =
    (successCallback, params = null) =>
    async (dispatch) => {
        await sendRequest(dispatch, {
            method: 'get',
            url: 'territory',
            data: params,
            successCallback: successCallback,
        });
    };

export const getObject = (id, successCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'get',
        url: 'territory/' + id,
        successCallback: successCallback,
    });
};

export const updateObject = (territoryId, data, successCallback, failCallback) => async (dispatch, getState) => {
    console.log('updateObject Action data')
    console.log(data)
    sendRequest(dispatch, {
        method: 'put',
        url: 'territory/' + territoryId,
        data: data,
        successCallback: successCallback,
        failCallback: failCallback,
        successMessage: 'Данные сохранены!',
    });
};

export const createObject = (objectData, successCallback, failCallback) => async (dispatch) => {
    await sendRequest(dispatch, {
        method: 'post',
        url: 'territory',
        data: objectData,
        failCallback: failCallback,
        successCallback: successCallback,
        successMessage: 'Данные сохранены!',
    });
};

export const getTerritoryClossList =
    (successCallback, params = null) =>
    async (dispatch) => {
        await sendRequest(dispatch, {
            method: 'get',
            url: 'terr_class',
            data: params,
            successCallback: successCallback,
        });
    };

export const getTerritoryGroupList =
    (successCallback, params = null) =>
    async (dispatch) => {
        await sendRequest(dispatch, {
            method: 'get',
            url: 'terr_group',
            data: params,
            successCallback: successCallback,
        });
    };

export const getTerritoryDistrictList =
(successCallback, params = null) =>
async (dispatch) => {
    await sendRequest(dispatch, {
        method: 'get',
        url: 'pass_adm_okr',
        data: params,
        successCallback: successCallback,
    });
};

export const getPkkData = async (like, successCallback, errorCallback = () => {}) => {
    const url = `${baseMapUrl}/api/pkk/search/`;

    fetch(url, {
        method: 'POST',
        body: JSON.stringify({ like }),
    })
        .then((response) => response.json())
        .then((data) => successCallback(data))
        .catch((e) => {
            console.log('error pkk', e);
            errorCallback();
        });
};

export const getWorkTypes = (params, successCallback) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'get',
        url: 'works_type/',
        data: params,
        successCallback: successCallback,
    });
};
