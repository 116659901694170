import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getUser } from '@app-actions';
import UserView from './UserView/UserView';
import UserEdit from './UserEdit/UserEdit';
import UserModalLoader from './UserModalLoader';

const UserIndex = ({ userId, showForm, toggleSidebar, reloadList, isEditable }) => {
    const [state, setState] = useState({ isItemDataLoaded: false, data: {}, showForm: showForm });
    const dispatch = useDispatch();

    const toggleMode = () => {
        setState((prev) => ({ ...prev, showForm: true }));
    };

    const handleSuccessEntityLoad = (response) => {
        setState((pr) => ({ ...pr, isItemDataLoaded: true, data: response }));
    };

    useEffect(() => {
        dispatch(getUser(userId, handleSuccessEntityLoad));
    }, []);

    return (
        <>
            {state.isItemDataLoaded ? (
                state.showForm ? (
                    <UserEdit userData={state.data} toggleSidebar={toggleSidebar} reloadList={reloadList} />
                ) : (
                    <UserView userData={state.data} switchToEdit={toggleMode} isEditable={isEditable} />
                )
            ) : (
                <UserModalLoader />
            )}
        </>
    );
};

UserIndex.defaultProps = {
    showForm: false,
};

UserIndex.propTypes = {
    userId: PropTypes.number,
    showForm: PropTypes.bool,
    toggleSidebar: PropTypes.func.isRequired,
    reloadList: PropTypes.func,
};

export default UserIndex;
