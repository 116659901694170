import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getPassport } from '@app-actions';
import PassportView from './PassportView';
import {useLocation} from "react-router-dom";

export default function PassportIndex({ toggleSidebar, passportId, secondaryBtnTitle, secondaryBtnLink, preview }) {


    const location = useLocation();
    const isUDS = location.pathname.includes('passport/list');

    const [state, setState] = useState({
        isItemDataLoaded: false,
        data: {
            name: null,
            toponym: null,
            adm_district: null,
            adm_okr: null,
            road_id: null,
            category: null,
            len: null,
            blen: null,
            hblen: null,
            geometryAsText: null,
            explication: [],
        },
    });
    const dispatch = useDispatch();

    const toggleMode = () => setState((prev) => ({ ...prev, showForm: true }));

    const handleSuccessEntityLoad = (response) => {
        setState((pr) => ({ ...pr, isItemDataLoaded: true, data: { ...pr.data, ...response } }));
    };

    useEffect(() => {
        dispatch(getPassport(passportId, handleSuccessEntityLoad));
    }, []);

    return (
        <PassportView
            name={state.data.name}
            toponym={isUDS ? state.data.toponym : ""}
            adm_district={state.data.adm_district}
            adm_okr={state.data.adm_okr}
            road_id={state.data.road_id}
            category={state.data.category}
            len={state.data.len}
            blen={state.data.blen}
            hblen={state.data.hblen}
            geometryAsText={state.data.geometryAsText}
            passportId={passportId}
            preview={preview}
            explication={state.data.explication}
            toggleMode={toggleMode}
            secondaryBtnTitle={secondaryBtnTitle}
            secondaryBtnLink={secondaryBtnLink}
            isItemDataLoaded={state.isItemDataLoaded}
        />
    );
}

PassportIndex.defaultProps = {
    secondaryBtnTitle: 'На карту',
    secondaryBtnLink: 'map',
};

PassportIndex.propTypes = {
    toggleSidebar: PropTypes.func,
    passportId: PropTypes.number,
    secondaryBtnTitle: PropTypes.string,
    secondaryBtnLink: PropTypes.string,
    preview: PropTypes.string,
};
