import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import RoutePath from '@app-universal/routing/RoutePath';
import { Link } from 'react-router-dom';

export default function CustomBreadcrumbs({ routes }) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Главная</Link>
            {routes.map((it, index) => {
                return it.disabled ? (
                    <span key={index} className={index === routes.length - 1 ? 'active' : ''}>{it.label}</span>
                ) : (
                    <Link key={index} to={RoutePath(it.link)}>
                        <span className={index === routes.length - 1 ? 'active' : ''}>{it.label}</span>
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
}

CustomBreadcrumbs.defaultProps = {
    routes: [],
};

CustomBreadcrumbs.propTypes = {
    routes: PropTypes.array,
};
