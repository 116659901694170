import React from 'react';
import PropTypes from 'prop-types';
import IconFilter from '@app-images/icon_filter.svg';
export default function SecondaryButton(props) {
    const { title, onClick, showIcon, ...rest } = props;
    return (
        <button
            className="g-button white with-icon filter"
            onClick={onClick}
            variant="outlined"
            color="primary"
            {...rest}
        >
            {showIcon && <img class="icon" src={IconFilter} class="icon-filter" />}
            {title}
        </button>
    );
}

SecondaryButton.defaultProps = {
    onClick: () => {},
    showIcon: false,
};

SecondaryButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    showIcon: PropTypes.bool,
};
