import React, { useState, useEffect } from 'react';
import IconSortBoth from '@app-images/icon_sort_both.svg';
import IconSortAsc from '@app-images/icon_sort_asc.svg';
import IconSortDesc from '@app-images/icon_sort_desc.svg';
import { orderBy } from 'lodash-es';

function useSorting(initialData) {
    const [sortingState, setSortingState] = useState({ data: initialData, sortField: null, direction: 'asc' });

    useEffect(() => {
        setSortingState(pr => ({...pr, data: initialData}));
    }, [initialData]);

    const sort = (field, direction = 'asc') => {
        if (sortingState.sortField === field) direction = sortingState.direction === 'desc' ? 'asc' : 'desc';

        const sorted = orderBy(sortingState.data, [field], [direction]);
        setSortingState((pr) => ({ ...pr, sortField: field, direction, data: sorted }));
    };

    const getIcon = (field) => {
        return sortingState.sortField !== field ? IconSortBoth : sortingState.direction === 'asc' ? IconSortAsc : IconSortDesc;
    };

    const Sorting = React.useMemo(() => {
        return [sortingState, setSortingState, sort, getIcon];
    }, [sortingState, setSortingState, sort, getIcon]);
    return Sorting;
}

export default useSorting;