import { ActionTypes } from '../constants';

import sendRequest from '@app-helpers/ApiRequestHelper/ApiClient';

export const auth = (email, password, rememberMe, loginErrorHandler) => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'post',
        url: 'user/login',
        data: {
            email: email,
            password: password,
            remember_me: rememberMe,
        },
        successCallback: () => {
            dispatch(loadAppData());
        },
        failCallback: loginErrorHandler,
    });
};

export const getAndSetUserData = () => async (dispatch, getState) => {
    return sendRequest(dispatch, {
        method: 'get',
        url: 'user/current',
        showNotifications: false,
        useCommonErrorsHandler: false,
        successCallback: (userData) => {
            dispatch({
                type: ActionTypes.AUTHED,
                currentUser: userData,
                currentUserId: userData.id,
                userName: userData.email,
                account: userData.account,
                userRoles: userData.roles,
                photoPath: userData.photoPath,
                isEmailConfirmed: userData.isEmailConfirmed,
                previousLoginsData: JSON.parse(localStorage.getItem('previousLoginsData')),
            });
        },
    });
};

export const initApp = () => async (dispatch, getState) => {
    await loadAppData()(dispatch, getState);

    dispatch({
        // уведомляем всех что инициллизация завершена
        type: ActionTypes.APP_INITED,
    });
};

export const loadAppData = () => async (dispatch, getState) => {
    return Promise.all([getAndSetUserData()(dispatch, getState)]);
};

export const logoutUser = () => async (dispatch, getState) => {
    sendRequest(dispatch, {
        method: 'get',
        url: 'user/logout',
        requestDataType: 'text',
        responseDataType: 'text',
        successCallback: () => {
            dispatch({
                type: ActionTypes.LOGOUT,
            });
        },
    });
};
