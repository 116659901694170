import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions.js';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

const useStyles = makeStyles((theme) => ({
    paper: {},
    list: {
        padding: 0,
    },
    selectIcon: {
        top: 'inherit',
        right: '4px !important',
    },
}));

const renderValue = (value) => `${value} / стр.`;

function Pagination(props) {
    const { paginationState, setPaginationState } = props;
    const classes = useStyles();

    const handleChangePage = React.useCallback(
        ({ selected }) => {
            setPaginationState({
                ...paginationState,
                currentPageNumber: selected,
            });
        },
        [paginationState, setPaginationState]
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event) => {
            setPaginationState({
                ...paginationState,
                currentPageNumber: 0,
                itemsPerPage: parseInt(event.target.value, 10),
            });
        },
        [paginationState, setPaginationState]
    );

    return (
        <div className={'hold-pagination'}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                            colSpan={6}
                            count={paginationState.totalItemsCount}
                            rowsPerPage={paginationState.itemsPerPage}
                            page={paginationState.currentPageNumber}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                // native: true,
                                MenuProps: {
                                    classes: { list: classes.list, paper: classes.paper },
                                },
                                classes: { icon: classes.selectIcon },
                                IconComponent: ExpandMoreOutlinedIcon,
                                renderValue,
                                onClose: () => setTimeout(() => document.activeElement.blur(), 0),
                            }}
                            labelRowsPerPage=""
                            labelDisplayedRows={({ from, to, count }) => from + '-' + to + ' из ' + count}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={(props) => (
                                <TablePaginationActions
                                    count={paginationState.totalItemsCount}
                                    page={paginationState.currentPageNumber}
                                    pageCount={paginationState.pageCount}
                                    rowsPerPage={paginationState.itemsPerPage}
                                    onChangePage={handleChangePage}
                                />
                            )}
                        />
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}

Pagination.propTypes = {
    paginationState: PropTypes.object.isRequired,
    setPaginationState: PropTypes.func.isRequired,
};

export default React.memo(Pagination);
