let tableColumns = [
    // { field: 'id', headerName: 'ID', flex: 1 },
    {
        field: 'group',
        headerName: 'Группа',
        flex: 1,
        editable: true,
        valueGetter: (value) => (value.value ? value.value.name : 'Не указано'),
    },
    {
        field: 'class',
        headerName: 'Класс',
        flex: 1,
        editable: true,
        valueGetter: (value) => (value.value ? value.value.name : 'Не указано'),
    },
    {
        field: 'district',
        headerName: 'Округ',
        flex: 2,
        editable: true,
    },
    {
        field: 'ID_6993',
        headerName: 'ID_6993',
        type: 'number',
        flex: 1,
        editable: true,
    },
    {
        field: 'len_6993',
        headerName: 'Длина улицы',
        type: 'number',
        flex: 1,
        editable: true,
    },
    {
        field: 'type_zone',
        headerName: 'Вид территории',
        type: 'number',
        flex: 2,
        editable: true,
        valueGetter: (value) => (value.value ? value.value.name : 'Не указано'),
    },
    {
        field: 'type_surve',
        headerName: 'Вид покрытия',
        type: 'number',
        flex: 2,
        editable: true,
        valueGetter: (value) => (value.value ? value.value.name : 'Не указано'),
    },
    // {
    //     field: 'type_serv',
    //     headerName: 'Вид услуги',
    //     type: 'number',
    //     flex: 1,
    //     editable: true,
    // },
    {
        field: 'len_curb',
        headerName: 'Протяж. с бордюрами',
        type: 'number',
        flex: 1,
        editable: true,
    },
    {
        field: 'len_nocurb',
        headerName: 'Протяж. без бордюров',
        type: 'number',
        flex: 1,
        editable: true,
    },
];

tableColumns = Object.freeze(tableColumns);

export { tableColumns };
